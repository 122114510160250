import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {

  deleteTiposConjuntosAnalisis,
  getTiposConjuntosAnalisisPaginated,

  getTiposConjuntosAnalisisSearch,
} from "../../../../api/Configuracion";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const TipoConjuntoDeAnalisis = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tiposConjuntos, setTiposConjuntos] = useState([]);
  const [tiposConjuntosdId, setTiposConjuntosId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [key, setKey] = useState(1);

  useEffect(() => {
    getTiposConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTiposConjuntos(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tpcana,
              descripcion: res.desc_tpcana,
              habilitado: res.status,
            };
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    getTiposConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTiposConjuntos(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tpcana,
              descripcion: res.desc_tpcana,
              habilitado: res.status,
            };
          })
        );
      }
    );
  }, [paginaActual, orderBy]);

  useEffect(() => {
    if (searchTerm === "") {
      getTiposConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTiposConjuntos(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tpcana,
                descripcion: res.desc_tpcana,
                habilitado: res.status,
              };
            })
          );
        })
        .catch((err) => console.error("Error al obtener datos :", err));
    } else {
      getTiposConjuntosAnalisisSearch("", searchTerm)
        .then((res) => {
          setPaginaActual(1);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTiposConjuntos(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tpcana,
                descripcion: res.desc_tpcana,
                habilitado: res.status,
              };
            })
          );
        })
        .catch((err) => console.error("Error al obtener datos ", err));
    }
  }, [searchTerm]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      //handleDelete(valueRow.id)
      setTiposConjuntosId(valueRow.id);
      setModalStatus2(true);
    }
  };
  const handleDelete = (id) => {
    deleteTiposConjuntosAnalisis(id)
      .then(() => {
        setTiposConjuntosId("");
        // setKey(key + 1);
        setMsjToast("Tipo de de Lubricante eliminado con exito");
        let paginaActualTemp = paginaActual;
        if (tiposConjuntos.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getTiposConjuntosAnalisisPaginated(
          paginaActualTemp,
          orderBy,
          searchTerm
        );
      })
      .then((res) => {
        setSuccess(true);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setMsjToast("Tipo de Conjunto de Análisis eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setTiposConjuntos(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tpcana,
                descripcion: res.desc_tpcana,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        // setKey(key + 1);
        setMsjToast("Error al eliminar El tipo de Conjunto de Analisis");
        setError(true);
      });
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };
  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success && (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        )}

        {error && (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        )}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key+1)
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
          getTiposConjuntosAnalisisPaginated(
            paginaActual,
            orderBy,
            searchTerm
          ).then((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            setTiposConjuntos(
              res.data.items.map((res) => {
                return {
                  id: res.id,
                  codigo: res.codigo_tpcana,
                  descripcion: res.desc_tpcana,
                  habilitado: res.status,
                };
              })
            );
          });
        }}
        type={"tipoConjuntoAnalisis"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipo de Conjunto de Análisis"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo tipo de conjunto de análisis"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tiposConjuntos}
        cRows={20}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        totalRows={itemsCount}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(tiposConjuntosdId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipoConjuntoDeAnalisis;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
