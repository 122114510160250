import React, { useState, useEffect } from 'react'
import { lookupUser, updateUser } from '../../api/auth.js';
import { useAuth } from '../../context/AuthContext.jsx';
import { useForm } from 'react-hook-form';
import { Box, Divider } from '@mui/material';
import { colors } from "../../assets/styles/theme.ts";
import { Styled, BoxMiCuenta, Title, Description, Header, BoxImg, Body, BoxDataUsuario } from './MiCuentaStyled';
import { EditOutlined, AccountCircleOutlined, AlternateEmailOutlined, LockResetOutlined, VisibilityOff, Visibility } from '@mui/icons-material';
import { IconButton, TextField, InputAdornment, Typography } from '@mui/material';
import { loginUser } from "../../api/auth.js";
import CustomButton from '../../components/Button/CustomButton.tsx';
import RenderImg from '../../components/RenderImg/RenderImg.tsx';
import LimsOil from "../../assets/Login/LimsOil.png";

const MiCuenta = () => {
  const { token } = useAuth();
  const [editState, setEditState] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [preview, setPreview] = useState(null);
  const [user, setUser] = useState({});
  const tokenId = token ? token.id : "";
  const { register, handleSubmit, setValue, reset } = useForm();
  const [inputValues, setInputValues] = useState(Array(3).fill(""));
  const [statusPw, setStatusPw] = useState(null);

  const toggleEdit = (index, name) => {
    setEditState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    if (!editState[index]) {
      if (name === 'fullName') {
        setValue('fullName', `${user?.firstName ?? ''} ${user?.lastName ?? ''}`);
      } else if (name === 'email') {
        setValue('email', user?.email ?? '');
      }
    }
  };
  const handleInputChange = (index, value) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);
    if (value === '') {
      setStatusPw(null);
    }
  };


  const fetchUser = async () => {
    try {
      const res = await lookupUser(tokenId);
      setUser(res?.data ?? {});
    } catch (err) {
      console.error(err);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileSizeInBytes = file.size;
    const fileSizeInKB = fileSizeInBytes / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;

    if (fileSizeInMB <= 50) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setPreview(URL.createObjectURL(file));
        setValue("img", base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handlePasswordActual = async (pw) => {
    try {
      await loginUser({
        login: user?.login ?? "",
        password: pw ?? ""
      });
      setStatusPw(true);
      return true;
    } catch (err) {
      console.error('Error en la validación de la contraseña:', err);
      setStatusPw(false);
      return false;
    }
  };

  const onSubmit = async (value) => {
    const isPasswordValid = await handlePasswordActual(value?.passwordActual);

    const [firstName, ...lastName] = value?.fullName?.split(" ") || [];
    let json = {
      firstName: firstName ?? user?.firstName,
      lastName: lastName.join(" ") || user?.lastName,
      email: value?.email ?? user?.email,
      passwordHash: value?.password,
      id: tokenId,
      login: token?.login,
      createdDate: new Date().toISOString(),
      activated: true,
      rol: user?.rol,
      imageUrl: value?.img,
      tenantId: 1,
      createdBy: "admin",
      status: "ACTIVE",
      mostrar: "SI",
    };
    if (!json.passwordHash) {
      delete json.passwordHash;
    }
    if (!json.imageUrl.length > 0) {
      delete json.imageUrl;
    }
    if (isPasswordValid) {
      try {
        await updateUser(json);
        await fetchUser();
        window.location.reload();
      } catch (error) {
        console.error('Error during update or fetch:', error);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchUser = async () => {
      try {
        const res = await lookupUser(tokenId);
        if (isMounted) {
          console.log(res.data)
          setUser(res?.data ?? {});
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };
    fetchUser();
    return () => {
      isMounted = false;
    };
  }, [tokenId]);


  const renderData = () => {
    const data = [
      {
        title: "Nombre y Apellido",
        icon: <AccountCircleOutlined fontSize='medium' />,
        name: "fullName",
        type: "text",
        placeholder: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
      },
      {
        title: "Email",
        icon: <AlternateEmailOutlined fontSize='medium' />,
        name: "email",
        type: "email",
        placeholder: user?.email ?? ''
      },
      {
        title: "Contraseña actual",
        icon: <LockResetOutlined fontSize='medium' />,
        name: "passwordActual",
        type: "password",
        placeholder: "**********"
      },
      {
        title: "Nueva contraseña",
        icon: <LockResetOutlined fontSize='medium' />,
        name: "password",
        type: "password",
        placeholder: "**********"
      }
    ];

    return data.map((res, i) => (
      <BoxDataUsuario
        key={i}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "20px"
        }}
      >
        <BoxDataUsuario>
          <Title sx={{ fontSize: "14px", fontWeight: "700", display: "flex", gap: "10px" }}>
            <span>{res.icon}</span>
            {res.title}
          </Title>

          {editState[i] ? (
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  type={res.type === "password" && !showPassword ? "password" : "text"}
                  variant="standard"
                  {...register(res.name, {
                    onChange: (e) => {
                      if (e.target.value.trim() !== '') {
                        const [firstName, ...lastName] = e.target.value.split(" ");
                        setValue("firstName", firstName);
                        setValue("lastName", lastName.join(" "));
                        handleInputChange(res.name !== "password" && i, e.target.value);
                      } else {
                        setValue(res.name, user[res.name]);
                      }
                    },
                  })}
                  className="input"
                  size="small"
                  placeholder={res.placeholder}
                  disabled={res.name === "password" && !inputValues[2]}
                  error={inputValues[2] && statusPw === false && res.name === "passwordActual" ?
                    true
                    : false}
                  helperText={inputValues[2] && statusPw === false && res.name === "passwordActual" ?
                    "Contraseña actual incorrecta"
                    : null}
                  InputProps={{
                    endAdornment: res.type === "password" && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((prevShowPassword) => !prevShowPassword)
                          }
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton style={{ cursor: "pointer" }} onClick={() => toggleEdit(i, res.name)}>
                  <EditOutlined sx={{ color: colors.turquoise }} />
                </IconButton>
              </Box>

            </>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Description>{inputValues[i] || res.placeholder}</Description>
              <IconButton style={{ cursor: "pointer" }} onClick={() => toggleEdit(i, res.name)}>
                <EditOutlined sx={{ color: colors.turquoise }} />
              </IconButton>
            </Box>
          )}
        </BoxDataUsuario>

        <Divider sx={{ color: colors.turquoise }} />
      </BoxDataUsuario>
    ));
  };
  return (
    <Styled>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <BoxMiCuenta>
          <Header>
            <Title sx={{ fontSize: "24px", fontWeight: "600" }}>
              Mi Cuenta
            </Title>

            <Box sx={{ display: "flex", gap: "5px", alignItems: "flex-end" }}>
              <BoxImg>
                {preview ? (
                  <img src={preview} alt="Preview" className="img" style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                ) : user?.imageUrl ? (
                  <img src={`data:image/png;base64,${user.imageUrl}`} alt="Profile" className="img" style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                ) : (

                  <RenderImg img={LimsOil} className="img" />
                )}
              </BoxImg>
              <IconButton component="label">
                <EditOutlined sx={{ color: colors.turquoise }} fontSize='large' />
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  {...register("img", {
                    onChange: (e) => {
                      const file = e.target.files[0];
                      handleImageChange(file);
                    },
                  })}
                  onChange={handleImageChange}
                />
              </IconButton>
            </Box>


          </Header>

          <Body>
            {renderData()}
            <Box sx={{ width: "100%", margin: "auto", padding: "10px 0px", display: "flex", gap: "10px", justifyContent: "center" }}>
              <CustomButton
                label={"Cancelar"}
                className="buttonColorDisabled"
                size="large"
                fullWidth
                disableRipple
                onClick={() => {
                  setInputValues("");
                  setPreview(null);
                  reset();
                  handlePasswordActual();
                }}
              />
              <CustomButton
                label={"Confirmar"}
                type="submit"
                className="buttonColorDefault"
                size="large"
                fullWidth
                disableRipple
              />
            </Box>
          </Body>
        </BoxMiCuenta>

      </form>
    </Styled >
  )
}

export default MiCuenta