import React from "react";
import { Styled, DivImg, Title, DivButtonsNav } from "./HeaderStyled";
import { useAuth } from "../../context/AuthContext.jsx";
import { useNavigate } from "react-router-dom";
import { datos } from "./datos.js";
import Usuario from "./Usuario/Usuario.jsx";
import LimsOil from "../../assets/Login/LimsOil.png";
import RenderImg from "../RenderImg/RenderImg.tsx";
import CustomButton from "../Button/CustomButton.tsx";

import Modal from "../Modal/Modal.jsx";

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, token } = useAuth();
  const [data, setData] = React.useState("");
  const [statusModal, setModalStatus] = React.useState(false);
  const [labelModal, setLabelModal] = React.useState("");
  const roleUser = token?.rol;
  // console.log(roleUser);

  const handleClickButtonNav = (value, dt) => {
    setData(dt);
    setTimeout(() => {
      switch (value) {
        case "Inicio":
          setModalStatus(false);
          navigate("/task");
          break;
        case "Informes de Muestras":
          setModalStatus(false);
          navigate("/informesmuestras");
          break;
        case "Proveedores":
          setModalStatus(false);
          navigate("/proveedores");
          break;
        case "Intervenciones":
          setModalStatus(false);
          navigate("/intervenciones");
          break;
        default:
          setModalStatus(true);
          break;
      }
    }, 20);
    setLabelModal(
      value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );
  };

  const Buttons = () => {
    const buttons = datos?.map((ress) => {
      return ress[roleUser]?.buttons?.map((res, key) => (
        <div key={key} style={{ display: "flex" }}>
          <RenderImg img={res.icon} />
          <CustomButton
            onClick={() => {
              handleClickButtonNav(
                res.label,
                ress[roleUser][res.label.toLowerCase()]
              );
            }}
            label={res.label}
            className={"buttonsNav"}
          />
        </div>
      ));
    });
    return <DivButtonsNav>{buttons}</DivButtonsNav>;
  };

  return (
    <Styled>
      <DivImg>
        <RenderImg img={LimsOil} className="img" />
        {!isAuthenticated && (
          <Title>
            Software de Gestión de Laboratorios de Análisis de Lubricantes
          </Title>
        )}
      </DivImg>
      {isAuthenticated && (
        <>
          {Buttons()}
          <Usuario/>
        </>
      )}
      <Modal
        datos={data}
        labelModal={labelModal}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        modalNav
        type={"nav"}
      />
    </Styled>
  );
};

export default Header;
