import React, { useReducer, useEffect, useState } from 'react';
import { StyledModalcomponent } from '../../../Styled';
import { TextField } from '@mui/material';
import { updateTemplateEmails, createTemplateEmails } from '../../../../../api/Configuracion.js';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import Input from '../../../../Input/Input';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

const TemplateEmailsModal = (props) => {
  const propsTemplateEmails = (datosTemplateEmails) => {
    const ver = datosTemplateEmails?.button === "ver" ?? false;
    const editar = datosTemplateEmails?.button === "editar" ?? false;
    const crear = datosTemplateEmails?.button === "crear" ?? false;
    const datos = datosTemplateEmails?.datos ?? [];
    return { ver, editar, crear, datos }
  }
  const { ver, editar, crear, datos } = propsTemplateEmails(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    asunto: datos.asunto ?? '',
    cuerpo: datos.cuerpo ?? '',
  });
  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['codigo', 'descripcion', 'asunto', 'cuerpo'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (ver) {
      return;
    }
    setForm({ type: 'SET_FIELD', field: name, value });
  };

  const titulo = () => {
    const titulo = ver ? "Ver template email" : editar ? "Editar template email" : "Crear o editar template email";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formatterForm = {
      id: form.id,
      codigo_t_em: form.codigo,
      desc_t_em: form.descripcion,
      subject_t_em: form.asunto,
      body_t_em: form.cuerpo,
      "from_t_em": "noreply@empresa.com",
      "attachments_t_em": "NO",
      "created_by": "admin",
      "created_date": "2024-07-11T21:00:00Z",
      "last_modified_by": null,
      "last_modified_date": null,
      "status": "ACTIVE",
      "eliminado": false,
      "tenantId": 1
    };
    if (editar) {
      updateTemplateEmails(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Template email modificado con exito",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo editar el template email",
            status: "error",
          });
        });
    } else {
      createTemplateEmails(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({ msj: "Template email creado con exito", status: "success" });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({ msj: "No se pudo crear el template email", status: "error" });
        });
    }
  };

  const renderInputHtmlParser = () => {
    const isDisabled = ver;

    const inputHtmlParser = (
      <>
        <TextField
          label="Cuerpo"
          multiline
          name="cuerpo"
          minRows={2}
          maxRows={6}
          fullWidth
          value={form.cuerpo}
          onChange={handleChange}
          disabled={isDisabled}
        />
        <p className="labelVistaPrevia">Vista previa</p>
        <div className="divVistaPrevia">
          {parse(DOMPurify.sanitize(form.cuerpo ?? ""))}
        </div>
      </>
    );

    if (crear || editar || ver) {
      return (
        <>
          {inputHtmlParser}
        </>
      );
    }
  };
  return (
    <StyledModalcomponent>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <form className="formulario" onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div className="templateEmail">
          <div className="divLabel">
            <p className="titulo"> {titulo()}</p>
          </div>

          <div className="Ainput">
            <Input
              name="codigo"
              label="codigo"
              type="text"
              variant="outlined"
              className="input"
              value={form.codigo}
              onChange={handleChange}
              disabled={ver && true}
            />
            <Input
              name="descripcion"
              label="descripcion"
              type="text"
              variant="outlined"
              className="input descripcion"
              value={form.descripcion}
              onChange={handleChange}
              disabled={ver && true}
            />
          </div>
          <div className="Binput" >
            <Input
              name="asunto"
              label="asunto"
              type="text"
              variant="outlined"
              className="input asunto"
              value={form.asunto}
              onChange={handleChange}
              disabled={ver && true}
            />
          </div>
          <div className="Cinput">
            {renderInputHtmlParser()}
          </div>
        </div>
        <div className="divButtonsItem" style={{ margin: "auto" }}>
          {renderButtons()}
        </div>
      </form>
    </StyledModalcomponent>
  )
}

export default TemplateEmailsModal