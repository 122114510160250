import React, { useReducer, useState, useEffect } from 'react';
import { StyledModalcomponent } from '../../../Styled.jsx';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import {  getTipoLubricantes, createLubricante, updateLubricante } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';


const Lubricantes = (props) => {
  const propsLubricantes = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsLubricantes(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [tiposLubricantes, setTiposLubricantes] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    tipoLubricante: datos.tipo ?? '',
    habilitado: datos.habilitado === "SI" || datos.habilitado === "ACTIVE"  ? true : false
  });
  useEffect (() => {
  //  getTipoLubricantes().then(res => {
   ///   setTiposLubricantes(res.data.items.map(res => ({ value: res.id, label: res.desc_tplub })))
  //  console.log(JSON.stringify(res.data))
    //})
    
    const fetchTipoLubricantes = async () => {
      const res = await getTipoLubricantes();
      const unidades = res?.data?.items.map(item => ({
        value: item.id,
        label: item.desc_tplub,
      }));
      setTiposLubricantes(unidades);
      if (datos) {
        const matchOption = unidades.find(option => option.label === datos.tipo);
        if (matchOption ) {
          setSelectedOption(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }
    };
    fetchTipoLubricantes();
  }, []);
  
  useEffect(() => {
    if(selectedOption){
    setForm({ type: 'SET_FIELD', field: "tipoLubricante", value: selectedOption.value });}
  }, [selectedOption]);

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['codigo', 'descripcion', 'tipoLubricante', 'habilitado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };

  const titulo = () => {
    const titulo = ver ? "Ver lubricante" : editar ? "Editar lubricante" : "Crear o editar lubricantes";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();    
    setIsLoading(true);

    let formattedData = {      
      "id": form.id,
      "codigo_lub": form.codigo.toString(),
      "desc_lub": form.descripcion,
      "tipo_lub_id": form.tipoLubricante.toString(),
      "created_by": "dasfdasd",
      "created_date": new Date(),
      "last_modified_by": "1",
      "last_modified_date":new Date(),
      "status": form.habilitado?"SI":"NO",
      "tenantId":1,
      "eliminado": false
    
  }
 // alert(JSON.stringify(formattedData))

  if(editar){
    updateLubricante(formattedData).then(res => {     
      setIsLoading(false);       
      props.onClick({msj:"Lubricante modificado con éxito", status:"success"});        
    });
  }else{ 
    createLubricante(formattedData).then(res => {      
      setIsLoading(false);
      props.onClick({msj:"Lubricante Creado con exito", status:"success"});   
    });
  }
  }

  return (
    <StyledModalcomponent>
 <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    
    <div classname="toast" >      {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
      {error? <Toast type={"error"} title={msjToast} close={() => setError(false)}/> : null}
    </div> 
      <div className="lubricantes">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <div className="divInputsItem">
          <div className="divSubLabel">
          </div>
          <form className="formulario" onSubmit={handleSubmit} >
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={ver}
              />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              onChange={handleChange}
              disabled={ver}
            />
           
            <Input
              reactSelect
              options={tiposLubricantes}
              value={selectedOption}
              onChange={(e) => { handleChange(e, "tipoLubricante") }}
              isDisabled={ver && true}
              placeholder="Selecciona el tipo"
            />
            <div className="divButtonsItem">
            {renderButtons()}
          </div>
          </form>

          

        </div>
      </div>

    </StyledModalcomponent>
  )
}

export default Lubricantes