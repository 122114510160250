import React, { useState } from "react";
import styled from "styled-components";
import Input from "../Input/Input";
import CustomButton from "../Button/CustomButton.tsx";
import SetIcon from "../../assets/Icons/Buttons/Clientes/SetIcon.svg";
import More from "../../assets/Icons/Buttons/More.svg";
import Print from "../../assets/Icons/Buttons/Muestras/Print.svg";
import { RenderImgStyled } from "../../Pages/Task/Items/SuperAdminStyled.js";
import { Search } from "@mui/icons-material";
import { colors, fonts } from "../../assets/styles/theme.ts";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import Checkboxx from "../Checkbox/Checkboxx.jsx";


const HeaderTable = (props) => {
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  let Styled;
  switch (props.styledHead) {
    case "cfg":
      Styled = StyledCfg;
      break;
    case "clientes":
      Styled = StyledClientes;
      break;
    case "muestras":
      Styled = StyledMuestras;
      break;
    case "proveedores":
      Styled = StyledProveedores;
      break;
    default:
      Styled = StyledNone;
  }
  const handleSet = () => {
    if (props.buttonSet) {
      props.buttonSet();
    }
    setKey((prevKey) => prevKey + 1);
  };
  const cfgHeader = () => {
    return (
      <>
        <div className="divHeader">
          <p className="title">{props.title ? props.title : "titleDefault"}</p>
        </div>
        <div className="divBody">
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, denominación, etc"}
            icon={<Search />}
            size={"small"}
            value={props.value}
            search
            isClearable={props.isClearable ?? false}
            onChange={(e) => {
              // setValueBuscador(e.target.value);
              if (props.valueBuscador) {
                props.valueBuscador(e.target.value);
              }
            }}
            className="inputComponent"
          />
          <CustomButton
            label={props.labelButton ? props.labelButton : "nada"}
            startIcon={<RenderImgStyled img={More} />}
            className="buttonHeaderTable"
            onClick={props.onClickButton && props.onClickButton}
          />
        </div>
      </>
    );
  };
  const clientesHeader = () => {
    // alert("hola");
    return (
      <div key={key}>
        <div className="divHeader">
          <p className="title">{props.title ? props.title : "titleDefault"}</p>
        </div>
        <div className="divBody">
          <div className="divInputsHeader">
            <Input
              inputProp={true}
              positionIcon={"start"}
              placeholder={"Buscar por código, descripción, tipo"}
              icon={<Search />}
              size={"small"}
              onChange={(e) => {
                // setValueBuscador(e.target.value);
                if (props.valueBuscador) {
                  props.valueBuscador(e.target.value);
                }
              }}
              value={props.value}
              search
              isClearable={props.isClearable ?? false}
              className="inputComponent"
            />
            {props.multiInputs &&
              props?.datosInputsHeaderTable?.map((res, i) => {
                return (<Input
                  key={1}
                  reactSelect
                  placeholder={res.placeholder}
                  options={res.datosSelect}
                  styles={res.customStyles}
                  stylesHeader
                  onChange={(e) => {
                    if (props.selectInput) {
                      props.selectInput(e);
                    }
                  }}
                />
                );
              })}
          </div>
          <div className="divCustomButtons">
            {props?.datosButtons?.map((res, i) => {
              return (
                <CustomButton
                  key={i}
                  label={res.label && res.label}
                  startIcon={
                    res.icon ? res.icon : <RenderImgStyled img={More} />
                  }
                  className={res.classname && res.classname}
                  size={res.size && res.size}
                  onClick={
                    res.navigate
                      ? () => {
                        navigate(res.navigate);
                      }
                      : props.onClickButton
                  }
                />
              );
            })}
          </div>
        </div>

      </div>
    );
  };
  const muestrasHeader = () => {
    return (
      <div key={key}>
        <div className="divHeader">
          <p className="title">{props.title ? props.title : "titleDefault"}</p>
        </div>
        <div className="divBody">
          <div className="divInputsHeader">
            <Input
              inputProp={true}
              positionIcon={"start"}
              placeholder={"Buscar por código, descripción, tipo"}
              icon={<Search />}
              size={"small"}
              style={{ width: "400px" }}
              onChange={(e) => {
                // setValueBuscador(e.target.value);
                if (props.valueBuscador) {
                  props.valueBuscador(e.target.value);
                }
              }}
              value={props.value}
              search
              isClearable={props.isClearable ?? false}
              className="inputComponent"
            />
            {props.multiInputs &&
              props?.datosInputsHeaderTable?.map((res, i) => {
                return res.inputSelect ? (
                  <Input
                    key={i}
                    reactSelect
                    stylesHeader
                    isClearable={props.isCleareable}
                    placeholder={res.placeholder}
                    options={res.datosSelect}
                    styles={res.customStyles}
                    onChange={(e) => {
                      if (props.selectInput) {
                        props.selectInput(e);
                      }
                    }}
                  />
                ) : (
                  <Input
                    key={i}
                    inputProp={true}
                    positionIcon={"start"}
                    placeholder={
                      !res.placeholder
                        ? "Buscar por código, descripción, tipo"
                        : res.placeholder
                    }
                    icon={<Search />}
                    size={"small"}
                    style={{ width: res.width }}
                    onChange={(e) => {
                      if (props.valueBuscadorDos) {
                        props.valueBuscadorDos(e.target.value);
                      }
                    }}
                    className="inputComponent"
                  />
                );
              })}
          </div>
          <div className="divCustomButtons">
            {props?.datosButtons?.map((res, i) => {
              return (
                <CustomButton
                  key={i}
                  label={res.label && res.label}
                  startIcon={
                    res.icon ? res.icon : <RenderImgStyled img={More} />
                  }
                  className={res.classname && res.classname}
                  size={res.size && res.size}
                  onClick={() => { props.onClickButton && props.onClickButton(res.label) }}
                />
              );
            })}
          </div>
        </div>
        {props.multiHeader &&
          <div className="divMultiHeader">
            <div className="divCheckBoxxMuestras">
              {props.datosMultiHeader?.checkbox.map((res, i) => {
                return  <Checkboxx
                  label={res.label}
                  value={res.value}
                  name={res.name}
                  key={i}
                  onChange={(e) => { props.onChangeCheckbox && props.onChangeCheckbox(e) }} />
              })}
            </div>
            <div className="divButtonsMultiHeader">
              <CustomButton
                className="buttonColorTable"
                size="small"
                label={"Muestras"}
                startIcon={<RenderImgStyled img={Print} />}
                variant={"contained"}
                onClick={() => { props.onClickButton && props.onClickButton("muestras") }}
              />
              <CustomButton
                label={"Resumen"}
                className="buttonColorTable"
                size="small"
                startIcon={<RenderImgStyled img={Print} />}
                variant={"contained"}
                onClick={() => { props.onClickButton && props.onClickButton("resumen") }}
              />
            </div>
          </div>
        }
        {props.clean && (
          <div className="divSet">
            <CustomButton
              label={"Limpiar filtros"}
              startIcon={<RenderImgStyled img={SetIcon} />}
              variant={"link"}
              onClick={() => {
                handleSet();
              }}
            />
          </div>
        )}

      </div>
    );
  };
  const proveedoresHeader = () => {
    return (
      <>
        <div className="divHeader">
          <p className="title">{props.title ? props.title : "titleDefault"}</p>
        </div>
        <div className="divBody">
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, tipo"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              // setValueBuscador(e.target.value);
              if (props.valueBuscador) {
                props.valueBuscador(e.target.value);
              }
            }}
            value={props.value}
            search
            isClearable={props.isClearable ?? false}
            className="inputComponent"
          />
          <CustomButton
            label={props.labelButton ? props.labelButton : "nada"}
            startIcon={<RenderImgStyled img={More} />}
            className="buttonHeaderTable"
            onClick={props.onClickButton && props.onClickButton}
          />
        </div>
      </>
    );
  }
  return (
    <Styled className={props.className}>
      {props.cfg && cfgHeader()}
      {props.clientes && clientesHeader()}
      {props.muestras && muestrasHeader()}
      {props.proveedores && proveedoresHeader()}
    </Styled>
  );
};

export default HeaderTable;

const StyledCfg = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  .divHeader {
    .title {
      font-family: ${fonts.primary};
      color: ${colors.blueDark};
      font-size: 20px;
      font-weight: 600;
    }
  }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .divInput {
      width: 40%;
    }
  }
`;
const StyledClientes = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  .divHeader {
    .title {
      font-family: ${fonts.primary};
      color: ${colors.blueDark};
      font-size: 20px;
      font-weight: 600;
    }
  }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .divInputsHeader {
      display: flex;
      width: 70%;
      gap: 20px;
    }
    .divInput {
      width: 40%;
    }
    .divInputSelect {
      width: 20%;
    }
  }
  .divCustomButtons {
    display: flex;
    gap: 5px;
    width: 30%;
    justify-content: end;
  }
  @media (max-width: 768px) {
    .divBody {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .divInputsHeader {
        flex-direction: column;
        width: 100%;
        .divInput {
          width: 100%;
        }
        .divInputSelect {
          width: 100%;
        }
      }
      .divCustomButtons {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
`;
const StyledMuestras = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  .divHeader {
    .title {
      font-family: ${fonts.primary};
      color: ${colors.blueDark};
      font-size: 20px;
      font-weight: 600;
    }
  }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .divInputsHeader {
      display: flex;
      width: 70%;
      gap: 20px;
      align-items: center;
    }
    .divInput {
      /* width: 40%; */
    }
    .divInputSelect {
      width: 20%;
    }
  }
  .divCustomButtons {
    display: flex;
    gap: 5px;
    width: 30%;
    justify-content: end;
  }
  .divMultiHeader{
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .divCheckBoxxMuestras{
    display: flex;
  }
  .divButtonsMultiHeader{
    display: flex;
    gap: 10px;
  }
  @media (max-width: 768px) {
  .divBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .divInputsHeader {
    flex-direction: column;
    width: 100% !important;
  }
  .divCheckBoxxMuestras{
    flex-direction: column;
  }
  .divInput,
  .divInputSelect {
    width: 100% !important;
    .inputComponent{
      width: 100% !important;
    }
    .inputSelect{
      width: 100% !important;
    }
  }
  .divCustomButtons {
    justify-content: flex-start;
    width: 100%;
  }
  .divMultiHeader {
    flex-direction: column;
  }
}

@media (min-width: 769px) and (max-width: 1400px) {
  .divBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .divInput {
    width: 30%;
  }
  .divInputSelect {
    width: 20% ;
  }
  .divInputsHeader {
    flex-wrap: wrap;
    width: 100% !important;
  }
  .divCustomButtons {
    justify-content: flex-start;
  }
}
`;
const StyledProveedores = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  .divHeader {
    .title {
      font-family: ${fonts.primary};
      color: ${colors.blueDark};
      font-size: 20px;
      font-weight: 600;
    }
  }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .divInputsHeader {
      display: flex;
      width: 70%;
      gap: 20px;
    }
    .divInput {
      width: 30%;
    }
    
  }
  .divCustomButtons {
    display: flex;
    gap: 5px;
    width: 30%;
    justify-content: end;
  }
  @media (max-width: 768px) {
    .divBody {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .divInputsHeader {
        flex-direction: column;
        width: 100%;
        .divInput {
          width: 100%;
        }
        .divInputSelect {
          width: 100%;
        }
      }
      .divCustomButtons {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
`;
const StyledNone = styled.div`
  background: red;
`;
