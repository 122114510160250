import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";

import Toast from "../../../../components/Toast/Toast";
import {
  getTipoEventos,
  deleteTipoEventos,
  getTiposEventosPaginated,
  getTipoEventosSearch,
} from "../../../../api/Configuracion";
import Modal2 from "../../../../components/Modal/Modal";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "abreviatura", label: "Abreviatura" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];
const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    abreviatura: "Abreviatura",
    habilitado: "Habilitado",
  },
];

const TipoEventos = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoEventos, setTipoEventos] = useState([]);
  const [tipoEventoId, setTipoEventoId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [key, setKey] = useState(1);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);

  useEffect(() => {
    getTiposEventosPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setTipoEventos(
        res.data.items.map((res) => {
          return {
            id: res.id,
            abreviatura: res.abrev_tpeve,
            codigo: res.codigo_tpeve,
            descripcion: res.desc_tpeve,
            habilitado: res.status,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    getTiposEventosPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setTipoEventos(
        res.data.items.map((res) => {
          return {
            id: res.id,
            abreviatura: res.abrev_tpeve,
            codigo: res.codigo_tpeve,
            descripcion: res.desc_tpeve,
            habilitado: res.status,
          };
        })
      );
    });
  }, [paginaActual, orderBy]);

  useEffect(() => {
    if (searchTerm === "") {
      getTiposEventosPaginated(paginaActual, orderBy, searchTerm).then(
        (res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          // alert(JSON.stringify(res.data))
          setTipoEventos(
            res.data.items.map((res) => {
              return {
                id: res.id,
                abreviatura: res.abrev_tpeve,
                codigo: res.codigo_tpeve,
                descripcion: res.desc_tpeve,
                habilitado: res.status,
              };
            })
          );
        }
      );
    } else {
      setPaginaActual(1);
      getTipoEventosSearch("", searchTerm).then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);

        // alert(JSON.stringify(res.data))
        setTipoEventos(
          res.data.items.map((res) => {
            return {
              id: res.id,
              abreviatura: res.abrev_tpeve,
              codigo: res.codigo_tpeve,
              descripcion: res.desc_tpeve,
              habilitado: res.status,
            };
          })
        );
      });
    }
  }, [searchTerm]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      //handleDelete(valueRow.id)
      setTipoEventoId(valueRow.id);
      setModalStatus2(true);
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDelete = (tpeve) => {
    deleteTipoEventos(tpeve)
      .then(() => {
        setTipoEventoId("");
        // setKey(key+1);
        setMsjToast("Tipo de Evento eliminado con exito");
        let paginaActualTemp = paginaActual;

        if ((tipoEventos.length - 1) === 0 && paginaActual > 1) {
          paginaActualTemp = (paginaActual - 1);
          setPaginaActual(paginaActualTemp)
        }

        return getTiposEventosPaginated(paginaActualTemp, orderBy, searchTerm);
      })
      .then((res) => {
        setSuccess(true);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTimeout(() => {
          setSuccess(false);
          setTipoEventos(
            res.data.items.map((res) => {
              return {
                id: res.id,
                abreviatura: res.abrev_tpeve,
                codigo: res.codigo_tpeve,
                descripcion: res.desc_tpeve,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        // setKey(key+1);
        setMsjToast("Error al eliminar El tipo de Evento")
        setError(true)
      });
  };
  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success && <Toast key={key} title={msjToast} close={() => setSuccess(false)} />}
        {error && <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key+1)
            setSuccess(true);
            setMsjToast(msj);

          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
          getTiposEventosPaginated(paginaActual, orderBy, searchTerm).then(
            (res) => {
              setPageCount(res.data.totalPages);
              setItemsCount(res.data.totalItems);
              setTipoEventos(
                res.data.items.map((res) => {
                  return {
                    id: res.id,
                    abreviatura: res.abrev_tpeve,
                    codigo: res.codigo_tpeve,
                    descripcion: res.desc_tpeve,
                    habilitado: res.status,
                  };
                })
              );
            }
          );
        }}
        type={"tipoEventos"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipos de eventos"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo tipo de Evento"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoEventos}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(tipoEventoId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipoEventos;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
