import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import Modal2 from "../../../../components/Modal/Modal";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import Input from "../../../../components/Input/Input";
import { Box, Chip } from "@mui/material";
import {
  buttonsMuestras,
  buttonsMuestrasOrdenesTrabajo,
} from "../../../../components/ButtonsTable/ButtonsTable";
import {
  getOrdenesTrabajo,
  getEstadoOrdenesOptions,
  getOrdenesTrabajoAllFilters,
  deleteOrdenTrabajo,
} from "../../../../api/Muestras";
import { getClienteOptions, getPlantaOptions } from "../../../../api/Clientes";
import moment from "moment";

const columns = [
  { id: "fechaProgr", label: "Fecha Progr." },
  { id: "codigo", label: "Código" },
  { id: "lote", label: "Lote" },
  { id: "denominacion", label: "Denominación" },
  { id: "cliente", label: "Cliente" },
  { id: "planta", label: "Planta" },
  { id: "estado", label: "Estado" },
  { id: "buttons" },
];

const rows = [
  {
    fechaProgr: "fecha",
    codigo: "codigo",
    lote: "lote",
    denominacion: "denominacion",
    cliente: "cliente",
    planta: "planta",
    estado: "estado",
  },
];

const buttonsHeadTable = [
  {
    label: "Nueva orden",
    classname: "buttonHeaderTable",
  },
];

const datosInputsHeaderTable = [
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "hola",
    inputSelect: true,
    width: "250px",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "hola",
    inputSelect: true,
    width: "250px",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "chau",
    inputSelect: true,
    width: "250px",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
  {
    classnamediv: "divInputSelect",
    className: "inputComponent",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    inputSelect: false,
    width: "200px",
  },
];

const datosmultiHeader = {
  checkbox: [
    { label: "Sin Diagnóstico", value: "1", name: "a" },
    { label: "Sin Estado", value: "2", name: "b" },
    { label: "Sin Fecha Análisis", value: "3", name: "c" },
    { label: "Sin Imágenes", value: "4", name: "d" },
    { label: "Sin Resultados", value: "5", name: "e" },
    { label: "Sin Sugerencias", value: "6", name: "f" },
  ],
  buttons: [{ label: "Muestras" }, { label: "Resumen" }],
};

const OrdenesDeTrabajo = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [ordenId, setOrdenId] = useState("");
  const [datosModal, setDatosModal] = useState("");
  const [typeModal, setTypeModal] = useState("ordenesTrabajo");
  const [searchTerm, setSearchTerm] = useState("");
  const [ordenTrabajo, setOrdenTrabajo] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [selectedOptionCli, setSelectedOptionCli] = useState(null);
  const [selectedOptionPla, setSelectedOptionPla] = useState(null);
  const [selectedOptionEst, setSelectedOptionEst] = useState(null);
  const [key, setKey] = useState(1);
  const [prefilter, setPrefilter] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      getClienteOptions().then((res) => {
        setClientes(
          res.data.map((res) => {
            return { label: res.desc_cli, value: res.id };
          })
        );
      });
      getPlantaOptions().then((res) => {
        setPlantas(
          res.data.map((res) => {
            return { label: res.desc_pla, value: res.id };
          })
        );
      });
      getEstadoOrdenesOptions().then((res) => {
        setEstados(
          res.data.map((res) => {
            return { label: res.desc_ef_ot, value: res.id };
          })
        );
      });
    }

    fetchOrdenesDeTrabajoAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "primera"
    );
  }, [paginaActual]);

  const handleClickButonTable = (value, valueRow) => {
    const val = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (val !== "eliminar" && val !== "diagnostico" && val !== "excel") {
      setModalStatus(true);
      setTypeModal("ordenesTrabajo");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      console.log("val: ", val);
    }
    if (val === "diagnostico") {
      setModalStatus(true);
      setTypeModal("diagordenesTrabajo");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
    if (val === "eliminar") {
      setOrdenId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleClickButtonHeader = (e) => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const fetchOrdenesDeTrabajoAllFilters = async (
    pagina,
    order,
    search,
    action,
    idCli = "",
    idPla = "",
    idEst = ""
  ) => {
    const res =
      idCli === "" && idPla === "" && idEst === ""
        ? await getOrdenesTrabajo(pagina, order, search)
        : await getOrdenesTrabajoAllFilters(
            idCli,
            idPla,
            idEst,
            pagina,
            order,
            search
          );
    // console.log(res);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && ordenTrabajo.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Orden de trabajo eliminada con exito.");
      setSuccess(true);
    }

    setOrdenTrabajo(
      res.data.items.map((res) => {
        let fechaFormateada = moment(res.fecha_programada_otra).format(
          "DD/MM/YYYY"
        );
        return {
          fechaProgr: res.fecha_programada_otra,
          codigo: res.codigo_otra,
          lote: res.nro_lote_otra,
          denominacion: res.desc_otra,
          cliente: res.cliente.description,
          planta: res.planta.description,
          clienteId: res.cliente.id,
          plantaId: res.planta.id,
          estadoComp: res.estado ? res.estado : null,
          estado: (
            <Chip
              label={res?.estado?.desc_ef_ot || "N/A"}
              color={res?.estado?.color_ef_ot ? "default" : undefined}
              sx={{
                backgroundColor: res?.estado?.color_ef_ot ?? " ",
                color: "white",
              }}
            />
          ),
          id: res.id,
        };
      })
    );
  };

  const handleChangeClienteNew = (value) => {
    setSelectedOptionCli(!value ? null : value);
    fetchOrdenesDeTrabajoAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      value ? value.value : "",
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      selectedOptionEst === null ? "" : selectedOptionEst.value
    );
  };

  const handleChangePlantaNew = (value) => {
    setSelectedOptionPla(!value ? null : value);
    fetchOrdenesDeTrabajoAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      value ? value.value : "",
      selectedOptionEst === null ? "" : selectedOptionEst.value
    );
  };

  const handleChangeEstadoNew = (value) => {
    setSelectedOptionEst(!value ? null : value);
    fetchOrdenesDeTrabajoAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      value ? value.value : ""
    );
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchOrdenesDeTrabajoAllFilters(
      paginaActual,
      orderBy,
      value,
      "search",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      selectedOptionEst === null ? "" : selectedOptionEst.value
    );
  };

  const handleDeleteOrden = (id) => {
    setModalStatus2(false);
    deleteOrdenTrabajo(id).then(() => {
      setOrdenId("");
      let paginaActualTemp = paginaActual;
      if (plantas.length === 1 && paginaActualTemp > 1) {
        paginaActualTemp = paginaActualTemp - 1;
        setPaginaActual(paginaActualTemp);
      }

      return fetchOrdenesDeTrabajoAllFilters(
        paginaActualTemp,
        orderBy,
        searchTerm,
        "delete",
        selectedOptionCli === null ? "" : selectedOptionCli.value,
        selectedOptionPla === null ? "" : selectedOptionPla.value,
        selectedOptionEst === null ? "" : selectedOptionEst.value
      );
    });
  };

  return (
    <Styled>
      <div classname="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={typeModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchOrdenesDeTrabajoAllFilters(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : "",
              selectedOptionCli === null ? "" : selectedOptionCli.value,
              selectedOptionPla === null ? "" : selectedOptionPla.value,
              selectedOptionEst === null ? "" : selectedOptionEst.value
            );
          } else if (status === "error") {
            // setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          } else if (status === "cancel") {
            fetchOrdenesDeTrabajoAllFilters(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : "",
              selectedOptionCli === null ? "" : selectedOptionCli.value,
              selectedOptionPla === null ? "" : selectedOptionPla.value,
              selectedOptionEst === null ? "" : selectedOptionEst.value
            );
          }
          setModalStatus(false);
        }}
      />

      <div className="compexterno">
        <HeaderTable
          styledHead="muestras"
          muestras
          title="Ordenes de trabajo"
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => handleBuscador(value)}
          onClickButton={(e) => {
            handleClickButtonHeader(e);
          }}
          multiInputs
          selectInput={(value) => console.log(value)}
          buttonSet={() => {
            setSearchTerm("");
          }}
          clean
        />
        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione cliente"}
            options={clientes}
            stylesHeader
            onChange={(e) => {
              handleChangeClienteNew(e);
            }}
            value={selectedOptionCli}
          />
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione planta"}
            options={plantas}
            stylesHeader
            onChange={(e) => {
              handleChangePlantaNew(e);
            }}
            value={selectedOptionPla}
          />
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione estado"}
            options={estados}
            stylesHeader
            onChange={(e) => {
              handleChangeEstadoNew(e);
            }}
            value={selectedOptionEst}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={ordenTrabajo}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsMuestrasOrdenesTrabajo}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
        // valueBuscador={valueBuscador}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          handleDeleteOrden(ordenId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default OrdenesDeTrabajo;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }

  & .compexterno {
    position: relative;
  }
  & .compinterno {
    width: 500px;
    position: absolute;
    left: 29%;
    top: 43%;
    display: flex;
    flex-direction: row;

    gap: 10px;

    & .divInput {
      width: 100%;
    }
  }
`;
