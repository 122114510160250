import React, { useEffect, useState, useReducer } from "react";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import { StyledModalComponentTable } from "../../../Styled.jsx";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Tables from "../../../../Tables/Tables.jsx";
import { RenderImgStyled } from "../../../../../Pages/Task/Items/SuperAdminStyled.js";
import More from "../../../../../assets/Icons/Buttons/More.svg";
import { buttonsDefault, buttonsDefaultver } from "../../../../ButtonsTable/ButtonsTable.js";
import Toast from "../../../../Toast/Toast";
import Modal from "../../../Modal.jsx";
import Modal2 from "../../../Modal.jsx";
import Preloader from '../../../../Preloader/Preloader.jsx';

import {
  getProtocolosPaginated,
  getTipoUnidades,
  getConjuntosAnalisis,
  updateConjuntosAnalisis,
  createConjuntosAnalisis,
  getTiposConjuntosAnalisis,
  deleteProtocolos,
  getProtocolosPaginated2,
} from "../../../../../api/Configuracion";

const columns = [
  { id: "orden", label: "Orden" },
  { id: "analisis", label: "Análisis" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];
const rows = [
  {
    orden: "orden",
    analisis: "analisis",
    habilitado: "Habilitado",
  },
];

const CEAnalisis = (props) => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [tipoUnidades, setTipoUnidades] = useState([]);
  const [protocolos, setProtocolos] = useState([]);
  const [protocolosId, setProtocolosId] = useState("");
  const [estadosCargados, setEstadosCargados] = useState(false);
  const propsUnidades = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsUnidades(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
    const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [conjuntosAnalisisId, setConjuntosAnalisisId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);


  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      descripcion: datos.descripcion ?? "",
      tipoUnidad: datos.tipoId ?? "",
      habilitado:datos.habilitado === "SI" || datos.habilitado === "ACTIVE"  ? true : false,
    }
  );

  useEffect(() => {
    getTiposConjuntosAnalisis().then((res) => {
      setTipoUnidades(
        res.data.items.map((res) => ({ value: res.id, label: res.desc_tpcana }))
      );
    });

    const fetchTiposCAnalisis = async () => {
      const res = await getTiposConjuntosAnalisis();
      const unidades = res?.data?.items.map(item => ({
        value: item.id,
        label: item.desc_tpcana,
      }));
      setTipoUnidades(unidades);
      if (datos) {
        const matchOption = unidades.find(option => option.label === datos.tipo);
        if (matchOption ) {
          setSelectedOption(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }
    };
    fetchTiposCAnalisis();



    getProtocolosPaginated2(paginaActual, orderBy, searchTerm,99).then(
      (res) => {
        let protocolos = [];
       
        
            
        if ((form && form.id) || conjuntosAnalisisId!=="" ) {
        
          protocolos = res.data.items
            .filter((res) => {
             // alert(res.conjunto_id.id ,"/", conjuntosAnalisisId, "/", form.id);
              if(editar){                
                return res.conjunto_id.id === form.id;
              }else{              
                return res.conjunto_id.id === form.id?form.id:conjuntosAnalisisId;
              }
             // return res.conjunto_id.id === conjuntosAnalisisId;//?conjuntosAnalisisId:form.id;
            })
            .map((res) => {
              return {
                id: res.id,
                analisis: res.analisis_id.description,
                orden: res.orden_pro,
                habilitado: res.status,
                conjuntoAnalisis: res.conjunto_id.id,
              };
            });
        } else {
          
          protocolos = [
            
          ];
        }
        setProtocolos(protocolos);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
      }
    );
  }, []);

  useEffect(() => {
    if(selectedOption){
    setForm({ type: 'SET_FIELD', field: "tipoUnidades", value: selectedOption.value });}
  }, [selectedOption]);

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);

    const camposaValidar = [
      "codigo",
      "descripcion",
      "tipoUnidad",
      "habilitado",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      id: form.id,
      codigo_cana: form.codigo,
      desc_cana: form.descripcion,
      tipocana_id: form.tipoUnidad.toString(),
      created_by: "dasfdasd",
      created_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };
    if (editar) {
      updateConjuntosAnalisis(formattedData).then((res) => {
        setSuccess(true);
        setIsLoading(false);
        setMsjToast("Conjunto de análisis modificado con exito");
        setTimeout(() => {
          props.onClick();
        }, 100);
      });
    } else {
      createConjuntosAnalisis(formattedData).then((res) => {
        setIsLoading(false);       
        setEstadosCargados(true);
        setConjuntosAnalisisId(res.data);
        setDatosModal({ datos: { conjuntoAnalisis: res.data } });
        setSuccess(true);
        setMsjToast("Conjunto de Análisis creado con exito");
        // setTimeout(() => {props.onClick()}, 1500);
      });
    }
  };
  const titulo = () => {
    const titulo = ver
      ? "Ver conjunto de análisis"
      : editar
      ? "Editar conjunto de análisis"
      : "Crear o editar conjunto de análisis";
    return titulo;
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!estadosCargados && !ver && <CustomButton
                label="Confirmar"
                variant="contained"
                className="buttonColorDefault btn"
                type="submit"
                disabled={!editar ? !formCompleto : false} /> }
         {estadosCargados && <CustomButton
                label="Cerrar"
                variant="contained"
                className="buttonColorDefault btn"
                onClick={props.onClick}
                disabled={false} /> }
      </>
    );
  };
  const handleClickButonTable = (value, valueRow) => {
   
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setProtocolosId(valueRow.id);
      setModalStatus2(true);
    }
  };
  const handleDeleteProtocolo = (id) => {
    deleteProtocolos(id)
      .then(() => {
        setProtocolosId("");
        return getProtocolosPaginated(paginaActual, orderBy, searchTerm).then(
          (res) => {
            let protocolos = [];
            if (form && form.id) {
              protocolos = res.data.items
                .filter((res) => {
                 // alert(res.conjunto_id.id ,"/", form.id);
                  return res.conjunto_id.id === form.id;
                })
                .map((res) => {
                  return {
                    id: res.id,
                    analisis: res.analisis_id.description,
                    orden: res.orden_pro,
                    habilitado: res.status,
                    conjuntoAnalisis: res.conjunto_id.id,
                  };
                });
            } else {
              protocolos = [
                { id: "", analisis: "", orden: "", habilitado: "" },
              ];
            }

            setMsjToast("Metodo eliminado con exito");
            setTimeout(() => {
              setSuccess(false);
              setProtocolos(protocolos);
            }, 1500);
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
          }
        );
      })
      .catch((err) => console.error("Error al eliminar el Metodo:", err));
  };

  return (
    <StyledModalComponentTable>
             <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />

      <div className="toast" style={{ zIndex: "1000", left: "0px" }}>
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <div className="CEAnalisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>

        <div className="divBody">
          <div className="divInputsItem">
            <form className="formulario" onSubmit={handleSubmit}>
              <Input
                value={form.codigo}
                label="Código"
                className="input inputCodigo"
                variant="outlined"
                size={"small"}
                name="codigo"
                onChange={handleChange}
                disabled={ver || estadosCargados}
              />
              <Input
                value={form.descripcion}
                label="Descripción"
                className="input inputDesc"
                variant="outlined"
                size={"small"}
                name="descripcion"
                onChange={handleChange}
                disabled={ver || estadosCargados}
              />            
              <Input
              reactSelect
             
              options={tipoUnidades}
              value={selectedOption}
              onChange={(e) => { handleChange(e, "tipoUnidad") }}
              isDisabled={ver || estadosCargados}
              placeholder="Selecciona el tipo"
             
            />

              <FormControlLabel
                control={
                  <Switch
                  disabled={ver}
                    name="habilitado"
                    checked={form.habilitado}
                    onChange={handleChange}
                  />
                }
                label="Habilitar"
              />
              <div className="divButtonsItem">{renderButtons()}</div>
            </form>
          </div>
          <hr className="hr-vertical" />
          <div className="divTableCEAnilisis">
            <div className="divHeadTable">
              <p className="label">Protocolo del conjunto de análisis</p>
             
              <CustomButton
                disabled={conjuntosAnalisisId === "" && !editar}
                label="Nuevo análisis de conjunto"
                variant="contained"
                className="buttonHeaderTable"
                onClick={() => {
                  setModalStatus(true);
                 // alert(props.datos)
                  setConjuntosAnalisisId(conjuntosAnalisisId?conjuntosAnalisisId:props.datos.datos.id);
                  setDatosModal({
                    datos: { conjuntoAnalisis: conjuntosAnalisisId?conjuntosAnalisisId:props.datos.datos.id },
                  });
                }}
                startIcon={<RenderImgStyled img={More} />}
              />
            </div>
            {conjuntosAnalisisId === "" && ver ?  <Tables
                typeTable={"prop"}
                columns={columns}
                rows={protocolos}
                cRows={20}
                pagination={true}
                totalRows={itemsCount}
                // valueBuscador={valueBuscador}
                buttons={buttonsDefaultver}
                onClickButtonTable={(value, valuesRow) => {
                  if(conjuntosAnalisisId === ""){
                    valuesRow.conjuntoAnalisis = props.datos.datos.id
                  }else{
                    valuesRow.conjuntoAnalisis = conjuntosAnalisisId
                  }                  
                  handleClickButonTable(value, valuesRow);
                }}
              /> : (
              <Tables
                typeTable={"prop"}
                columns={columns}
                rows={protocolos}
                cRows={20}
                pagination={true}
                totalRows={itemsCount}
                // valueBuscador={valueBuscador}
                buttons={buttonsDefault}
                onClickButtonTable={(value, valuesRow) => {   
                  if(conjuntosAnalisisId === ""){
                    valuesRow.conjuntoAnalisis = props.datos.datos.id
                  }else{
                    valuesRow.conjuntoAnalisis = conjuntosAnalisisId
                  }                 
                  handleClickButonTable(value, valuesRow);
                }}
              />
            )}
            <Modal2
              type="eliminar"
              isOpen={statusModal2}
              onClose={() => setModalStatus2(false)}
              onClickSuccess={() => {
                setModalStatus2(false);
                handleDeleteProtocolo(protocolosId);
              }}
            ></Modal2>
          </div>
        </div>
        <Modal
          modalNav
          datos={datosModal}
          isOpen={statusModal}
          onClose={() => {
            setModalStatus(false);
            getProtocolosPaginated2(paginaActual, orderBy, searchTerm,99).then(
              (res) => {
                let protocolos = [];
               // alert(conjuntosAnalisisId)
                //alert(props.datos.conjuntoAnalisiss)
                //alert(form.id)

                if ((form && form.id) || conjuntosAnalisisId!=="" ) {
                
                  protocolos = res.data.items
                    .filter((res) => {
                     // alert(res.conjunto_id.id ,"/", conjuntosAnalisisId);
                      return res.conjunto_id.id === form.id;//?conjuntosAnalisisId:form.id;
                    })
                    .map((res) => {
                      return {
                        id: res.id,
                        analisis: res.analisis_id.description,
                        orden: res.orden_pro,
                        habilitado: res.status,
                        conjuntoAnalisis: res.conjunto_id.id,
                      };
                    });
                } else {
                  alert("vengo por aca")
                  
                  protocolos = [
                   
                  ];
                }
                setProtocolos(protocolos);
                setPageCount(res.data.totalPages);
                setItemsCount(res.data.totalItems);
              }
            );
          }}
          type={"itemConjuntoAnalisis"}
        />
      </div>
    </StyledModalComponentTable>
  );
};

export default CEAnalisis;
