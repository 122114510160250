import styled from "styled-components";
import { colors, fonts } from "../../assets/styles/theme.ts";
import { Box } from "@mui/material";
//Configuraciones
export const StyledModalcomponent = styled.div`
  width: 40%;
  height: auto;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  @media (max-width: 1370px) {
    width: 90%;
  }

  //Cfg
  .eliminar,
  .unidad,
  .tipoUnidades,
  .analisis,
  .tipoAnalisis,
  .tipoConjuntoAnalisis,
  .lubricantes,
  .tipoLubricantes,
  .tipoEventos,
  .componentes,
  .metodos,
  .itemConjuntoAnalisis,
  .tiposDeFalla,
  .causasDeFalla,
  .templateEmail,
  .estadoOrdenesDeTrabajo {
    .divLabel {
      background-color: ${colors.lightGray};
      border-radius: 10px;
      padding: 5px;
      max-width: 100%;
      text-align: center;
      .titulo {
        font-size: 24px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        margin: 0px;
        font-weight: bold;
      }
    }
    .divPrimerInput {
      display: flex;
      justify-content: space-between;
      .divInput {
        width: 80%;
      }
    }
    .divInputsItem {
      width: 95%;
      .formulario {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 25px 0px;
      }
      .input {
        fieldset {
          border-radius: 8px;
        }
      }
    }
    .divButtonsItem {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin: auto;
    }
    .divInputsItem {
      .inputCodigo {
        width: 60%;
      }
      .inputDesc {
        width: 100%;
      }
      .inputUnidad {
        width: 40%;
      }
      .inputSimbolo {
        width: 20%;
      }
      .inputCAnalisis {
        width: 60%;
      }
      .inputAnalisis {
        width: 100%;
      }
      .inputOrden {
        width: 40%;
      }
    }
    @media (max-width: 768px) {
      .divInput {
        width: 100%;
        .input {
          width: 100% !important;
        }
      }
    }
  }
  //Analisis
  .analisis {
    .divInputsItem {
      .inputDesc {
        width: 100%;
      }
      .inputUnidad {
        width: 50%;
      }
      .inputMetodo {
        width: 50%;
      }
      .inputTipoAnalisis {
        width: 50%;
      }
    }
  }
  //Lubricantes
  .lubricantes {
    .divSubLabel {
      padding-top: 20px;
      margin: 0px;
      .subLabel {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        font-family: ${fonts.primary};
        color: ${colors.darkGray};
      }
    }
    .divInputsItem {
      .inputCodigo {
        width: 60%;
      }
      .inputDesc {
        width: 98%;
      }
      .inputUnidad {
        width: 50%;
      }
      .inputLubricante {
        width: 100%;
      }
    }
  }
  //Componentes
  .componentes {
    .divInputsItem {
      .inputCodigo {
        width: 60%;
      }
      .inputDesc {
        width: 100%;
      }
    }
  }
  //Template Emails
  .templateEmail{
    display:flex;
    flex-direction: column;
    gap: 10px;
    .Ainput{
      display: flex;
      gap: 10px;
      .divInput{
        width:100%;
        .input{
          width:100%;
          fieldset{
            border-radius: 8px;
          }
        }
      }
    }
    .Binput{
      .divInput{
        width:100%;
        .input{
          width:50%;
          fieldset{
            border-radius: 8px;
          }
        }
      }
    }
    .Cinput{
      display:flex;
      flex-direction: column;
      gap: 10px;
      .divCuerpo{
        background-color: #ffffff;
        border-radius: var(--1, 8px);
        border: 1px solid #66bbd3;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 10px; 
        height: 50px;
        overflow-y: auto;
      }
      .divVistaPrevia{
        background-color: #ffffff;
        border-radius: var(--1, 8px);
        border: 1px solid #66bbd3;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;  
        max-height: 150px;
        overflow-y: auto;
      }
      .labelCuerpo,.labelVistaPrevia{
        font-size: 15px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        font-weight: 500;
        margin:0px;
      }
    }
  }
  //Tipos De falla
  .tiposDeFalla,
  .causasDeFalla {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .Ainput,
    .Binput {
      display: flex;
      justify-content: space-between;
      .divInput {
        width: 100%;
        .input {
          width: 50%;
          fieldset {
            border-radius: 8px;
          }
        }
        .descripcion {
          width: 100%;
        }
        .tipos {
          width: 43%;
        }
      }
    }
  }
  //Estados ordenes de trabajo
  .estadoOrdenesDeTrabajo{
    display:flex;
    flex-direction: column;
    gap: 10px;
    .Ainput,.Binput,.Cinput{
      display: flex;
      gap: 10px;
      .divInput{
        width:100%;
        .input{
          width:100%;
          fieldset{
            border-radius: 8px;
          }
        }
      }
    }
    .Cinput{
      align-items: flex-end;
      .divCheckBox{
        display: flex;
      }
    }
    @media (max-width: 768px) {
      .Ainput,.Binput,.Cinput{
        flex-direction: column;
      }
      .Cinput{
        align-items: flex-start;
        .divCheckBox{
          flex-direction: column;
        }
      }
    }
  }
  //Ver
  .ver {
    .divInputsItem {
      .inputCodigo {
        width: 60%;
      }
      .inputDesc {
        width: 100%;
      }
      .inputUnidad {
        width: 60%;
      }
      .inputSimbolo {
        width: 20%;
      }
    }
  }
  
  //Eliminar
  .divBodyEliminar {
    color: ${colors.blueDark};
    font-family: ${fonts.primary};
    font-size: 20px;
    text-align: center;
    padding: 10px 0px;
  }
  .divClose {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .divButtonsEliminar {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .divButtonsItem {
    display: flex;
    gap: 10px;
  }
`;

export const StyledModalComponentTable = styled.div`
  width: 80%;
  height: 90%;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 0px;

  //CEAnalisis
  .CEAnalisis {
    height: 90%;
    .divLabel {
      background-color: #dbe5e8;
      border-radius: 10px;
      text-align: center;
      margin: auto;
      padding: 5px;
      width: 70%;
      .titulo {
        font-size: 24px;
        color: #163c5b;
        margin: 0px;
        font-weight: bold;
        font-family: Inter;
      }
    }
    .divBody {
      display: flex;
      height: 100%;
      padding: 20px 10px;
    }

    .divInputsItem {
      width: 50%;
      .formulario {
        height: 100%;
      }
      .inputCodigo {
        width: 50%;
      }
      .inputDesc {
        width: 100%;
      }
      .inputTipo {
        width: 100%;
      }
      .input {
        fieldset {
          border-radius: 8px;
        }
      }
      .formulario {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: 100%;
        .divButtonsItem {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-top: auto;
        }
      }
    }
    .divTableCEAnilisis {
      width: 50%;
      .divHeadTable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px;
        padding: 10px 0px;
        .label {
          font-size: 14px;
          font-weight: 600;
          margin: 0px;
          font-family: ${fonts.primary};
        }
      }
      .styledTables {
        height: 95%;
      }
    }
  }
  .hr-vertical {
    height: 95%;
    margin: 10px;
    border-left: 2px solid #66bbd3;
  }
  @media (max-width: 768px) {
    width: 90%;
    .hr-vertical {
      display: none;
    }
    .divBody {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }
    .divInputsItem {
      width: 98% !important;
    }
    .divTableCEAnilisis {
      width: 98% !important;
    }
    .divInput {
      width: 100%;
      .inputDesc,
      .inputCodigo,
      .inputTipo {
        width: 100%;
      }
    }
  }
`;

export const StyledEstadosFinalesTables = styled.div`
  width: 55%;
  height: 85%;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  overflow: auto;
  .estadosFinales {
    .divLabel {
      background-color: ${colors.lightGray};
      border-radius: 10px;
      padding: 5px;
      max-width: 100%;
      text-align: center;
      .titulo {
        font-size: 24px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        margin: 0px;
        font-weight: bold;
      }
    }
    .label {
      font-family: ${fonts.primary};
    }
    .divBody {
      display: flex;
      height: 100%;
      padding-top: 25px;
      .formulario {
        width: 100%;
      }
      .divColumnas {
        display: flex;
        justify-content: space-between;
        fieldset {
          border-radius: 8px;
        }
        .inputDesc,
        .inputColorEstado {
          width: 70%;
        }
        .divInputColor,
        .switch {
          display: flex;
          justify-content: flex-end;
        }
      }
      .divColumnaUno,
      .divColumnaDos {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    .divColumnaUno,
    .divColumnaDos {
      width: 50%;
    }
    .divTableEstadosFinales {
      .styledTables {
        height: 40vh;
        .MuiTableContainer-root {
          height: 65%;
        }
      }
    }
    .divHeadTable {
      display: flex;
      justify-content: space-between;
      align-items: end;
      padding: 5px 0px;
      .label {
        font-family: ${fonts.primary};
        font-weight: 600;
        margin: 0;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      .divColumnas {
        display: flex;
        flex-direction: column;
      }
      .divColumnaUno,
      .divColumnaDos {
        width: 100%;
      }
      .divInput {
        .MuiFormControl-root {
          width: 100% !important;
        }
      }
      .inputColorEstado {
        width: 100% !important;
      }
      .switch {
        justify-content: center !important;
      }
    }
  }
  ///
  .divTableCEAnilisis {
    padding: 20px 0px;
    .divHeadTable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;
      .label {
        font-size: 14px;
        font-weight: 600;
        margin: 0px;
      }
    }
    .styledTables {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .table {
        height: 100%;
        overflow: hidden;
      }
    }
  }
  .divButtonsItem {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 1370px) {
    width: 80%;
  }
`;

//Clientes
export const StyledModalcomponentClientes = styled.div`
  width: 60%;
  height: auto;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 50px;
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
  }
  fieldset {
    border-radius: 8px;
  }
  .clientes,
  .plantas,
  .puntosDeToma,
  .equipos {
    .divLabel {
      background-color: ${colors.lightGray};
      border-radius: 10px;
      padding: 5px;
      max-width: 100%;
      text-align: center;
      .titulo {
        font-size: 24px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        margin: 0px;
        font-weight: bold;
      }
    }
    .inputCodigo {
      width: 50%;
    }
    .inputDenominacion {
      width: 100%;
    }
    .inputDireccion {
      width: 100%;
    }
    .divUltimoInputs {
      display: flex;
      .divInput:nth-child(1) {
        width: 65%;
      }
      .divInput:nth-child(2) {
        width: 35%;
      }
      .inputEmail {
        width: 90%;
      }
      .inputTelefono {
        width: 100%;
      }
      display: flex;
      justify-content: space-between;
    }
    .dropzone {
      width: 50%;
    }
    @media (max-width: 768px) {
      padding: 0px;
      .divInput,
      .input {
        width: 100% !important;
      }
      .divUltimoInputs,
      .divs {
        flex-direction: column;
        gap: 40px;
      }
      .dropzone {
        width: 90%;
      }
    }
  }
  .divInputsItem {
    padding: 40px 0px 0px 0px;
    .formulario {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
  .divButtonsItem {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: auto;
  }
  //Plantas
  .plantas {
    .inputCliente {
      width: 70%;
    }
    .inputDenominacion {
      width: 100%;
    }
    .inputEmailNoti {
      width: 100%;
    }
  }
  //Equipos
  .equipos {
    .divUno,
    .divDos,
    .divTres {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .divCliente,
      .divPlanta,
      .divTagPlanta,
      .divArea {
        width: 50%;
      }
      .inputCliente,
      .inputPlanta,
      .inputCodigo,
      .inputDenominacion,
      .inputTagPlanta,
      .inputArea {
        width: 100%;
      }
      .divCodigo {
        width: 40%;
      }
      .divDenominacion {
        width: 100%;
      }
    }
  }
  //Puntos de toma
  .puntosDeToma {
    .divUno,
    .divDos,
    .divTres {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    //div1
    .divEquipo,
    .divCliente {
      width: 50%;
    }
    .inputEquipo,
    .inputCliente {
      width: 100%;
    }
    //div2
    .divCodigo {
      width: 35%;
      .inputCodigo {
        width: 100%;
      }
    }
    .divDenominacion {
      width: 65%;
      .inputDenominacion {
        width: 100%;
      }
    }
    //div3
    .divLubricante {
      width: 35%;
      .inputLubricante {
        width: 100%;
      }
    }
    .divComponente {
      width: 65%;
      .inputComponente {
        width: 100%;
      }
    }
    //div4
    .divCuatro {
      .divTagExterno {
        width: 35%;
        .inputTagExterno {
          width: 100%;
        }
      }
    }
  }
`;
export const StyledModalTableClientes = styled.div`
  width: 80%;
  height: auto;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 50px 10px 0px 10px;
  @media (max-width: 768px) {
    width: 90%;
  }
  fieldset {
    border-radius: 8px;
  }
  .divLabel {
    background-color: ${colors.lightGray};
    border-radius: 10px;
    padding: 5px;
    max-width: 100%;
    text-align: center;
    .titulo {
      font-size: 24px;
      color: ${colors.blueDark};
      font-family: ${fonts.primary};
      margin: 0px;
      font-weight: bold;
    }
  }
  //.Intervenciones
  .intervenciones {
    .formulario {
      display: flex;
      padding: 20px 0px;
      gap: 15px;
    }
    .divInputs {
      width: 65%;
      gap: 40px;
      display: flex;
      flex-direction: column;
    }
    //div uno
    .divUno {
      display: flex;
      gap: 10px;
      .divCliente {
        width: 40%;
      }
      .divPlanta {
        width: 60%;
      }
      .inputCliente,
      .inputPlanta {
        width: 100%;
      }
    }
    //div dos
    .divDos {
      display: flex;
      gap: 10px;
      .divEquipo {
        width: 40%;
      }
      .divTipoDeEvento {
        width: 60%;
      }
      .inputEquipo,
      .inputTipoDeEvento {
        width: 100%;
      }
    }
    //div tres
    .divTres {
      display: flex;
      gap: 10px;
      align-items: end;
      .divDate {
        width: 30%;
      }
      .divDetalleDelEvento {
        width: 80%;
        .inputDetalleDelEvento {
          width: 100%;
        }
      }
    }
    //div cuatro
    .divCuatro {
      .divObservaciones {
        width: 100%;
        .inputObservaciones {
          width: 100%;
        }
      }
    }
    //tabla
    .divTabla {
      .titulo {
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        margin: 5px;
      }
      width: 35%;
      .table {
        height: 30vh;
      }
      .styledTables {
        border: 2px solid #afccd5;
        height: 80%;
        .pagination {
          padding: 5px 0px;
        }
      }
    }
    @media (max-width: 768px) {
      .formulario {
        display: flex;
        flex-direction: column;
      }
      .divInputs {
        width: 100%;
      }
      .divTabla {
        width: 100%;
      }
      .divUno,
      .divDos,
      .divTres {
        display: flex;
        flex-direction: column;
        .divDate {
          width: 100%;
        }
      }
      .divInput {
        width: 100% !important;
        input {
          width: 100% !important;
        }
      }
      .divDetalleDelEvento {
        width: 100%;
      }
    }
  }
  .divButtonsItem {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: auto;
  }
`;

//Muestras
export const StyledModalcomponentMuestras = styled(Box)`
  height: auto;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 70%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  /* padding: 20px; */
  fieldset {
    border-radius: 8px;
  }
  .divLabel {
    background-color: ${colors.lightGray};
    border-radius: 10px;
    padding: 5px;
    max-width: 100%;
    text-align: center;
    .titulo {
      font-size: 24px;
      color: ${colors.blueDark};
      font-family: ${fonts.primary};
      margin: 0px;
      font-weight: bold;
    }
  }
  .divButtonsItem {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: auto;
  }
  //ordenesdetrabajo
  .ordenesDeTrabajo {
    padding: 20px;
    .formulario {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 0px;
    }
    .divInputsUno,
    .divInputsDos {
      display: flex;
      gap: 10px;
      .divInputCliente,
      .divInputPlanta {
        width: 40%;
      }
      .divInputCodigo {
        width: 30%;
      }
      .divInputLote {
        width: 10%;
      }
      .divInputDescripcion {
        width: 60%;
      }
      .inputCliente,
      .inputPlanta,
      .inputCodigo,
      .inputLote,
      .inputDescripcion {
        width: 100%;
      }
      /* .inputDate{min-width: auto;} */
    }
    .divInputsUno {
      align-items: end;
    }
    @media (max-width: 768px) {
      .divInputsUno,
      .divInputsDos,
      .divButtonsItem {
        flex-direction: column;
        align-items: flex-start;
      }
      .divInput {
        width: 100% !important;
      }
      .MuiStack-root {
        width: 100%;
      }
      .styledButton {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
  //ordenesDeTrabajoPanelControl
  .ordenesDeTrabajoPanelControl {
    padding: 20px;
    .formularioPanelControl {
      padding: 20px 0px 50px 0px;
      display: flex;
      gap: 10px;
    }
    .divColumnaUno {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
      .divInputsPanelControl {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .divUnoPanelControl {
          display: flex;
          gap: 10px;
          .divInputCodigo {
            width: 20%;
          }
          .divInputLote {
            width: 10%;
          }
          .divInputDescripcion {
            width: 70%;
          }
          .inputCodigo,
          .inputLote,
          .inputDescripcion {
            width: 100%;
          }
        }
        .divDosPanelControl {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .MuiStack-root {
            padding: 0px;
          }
          .inputDate {
            width: 18%;
            .MuiInputBase-formControl {
              height: 45px;
              overflow: hidden;
              align-items: center;
            }
          }
          .inputDate2 {
            width: 18%;
            margin-top: 6px;
            .MuiInputBase-formControl {
              height: 45px;
              overflow: hidden;
              align-items: center;
            }
          }
          .divInputCliente,
          .divInputPlanta {
            width: 100%;
            .inputCliente,
            .inputPlanta {
              width: 100%;
            }
          }
        }
        .divTresPanelControl {
          display: flex;
          gap: 20px;
        }
        .divCuatroPanelControl {
          .title {
            font-weight: bold;
            margin: 10px 0px;
            font-family: Inter;
          }
          .styledTables {
            height: auto;
            .headTable {
              background: #dbe5e8;
            }
            .tableCell {
              padding: 10px;
              font-size: 12px;
              font-size: 12px;
            }
            .labelHead {
              font-size: 12px;
            }
          }
        }
        .styledTables {
          height: 30vh;
        }
      }
    }
    .divColumnaDos {
      width: 50%;
      .divHeaderColumnaDos {
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
        .buttonsDos {
          display: flex;
          gap: 20px;
        }
      }
      .styledTables {
        height: auto;
        .buttonColorDisabled {
          padding: 5px;
        }
        .headTable {
          padding: 10px;
        }
        .tableCell {
          font-weight: 400;
        }
        .MuiTableCell-root:last-child {
          font-weight: bold;
        }
        .labelHead {
          font-size: 11px;
        }
      }
    }
  }
  //notiPanelControlOrdenesDeTrabajo
  .notiOrdenesDeTrabajo {
    padding: 20px;
    .divLabel {
      padding: 10px 0px;
    }
    .divBody {
      display: flex;
      flex-direction: column;
      padding: 10px 0px;
      gap: 20px;
      .confirmacion {
        margin: auto;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
      }
      .divDescripcion {
        border: 4px solid #d9dd1d;
        border-radius: 10px;
        padding: 20px;
        .descripcion {
          font-family: Inter;
          font-size: 13px;
          font-weight: bold;
          margin: 0px;
        }
      }
    }
  }
  //Muestras
  .muestras {
    padding: 40px 40px 0px 40px;
    .formulario {
      padding: 20px 0px;
    }
    .columna {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      padding-bottom: 100px;
      .a,
      .b,
      .c,
      .d,
      .e,
      .f {
        display: flex;
        gap: 20px;
      }
      .a,
      .b {
        .divInput {
          width: 100%;
          .input {
            width: 100%;
          }
        }
      }
      .c {
        align-items: end;
        .MuiStack-root {
          width: 40%;
        }
        .divInput {
          width: 100%;
          .input {
            width: 100%;
          }
        }
      }
      .d,
      .e,
      .f {
        .divInputL {
          width: 40%;
        }
        .divInputR {
          width: 100%;
        }
        .input {
          width: 100%;
        }
      }
    }
  }
  .diagMuestras {
    padding: 20px;
    .divHeaderTable {
      display: flex;
      padding: 10px 0px;
      gap: 10px;
      button{
        font-size: 10px;
      }
      .divBtnResultado {
        width: 55%;
        .btnResultado {
          color: white;
        }
      }
      .divBtn {
        margin-left: auto;
      }
    }
    .divRowsMuestra {
      border: 2px solid #afccd5;
      border-radius: 10px;
      padding: 20px;
      width: 150px;
    }
    .formulario {
      display: flex;
      .columnaUno {
        width: 45%;
        .divInput {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          align-items:center;
          .divInputEquipo,
          .divInputLubricante
          {
            width: 25%;
            .input {
              width: 100%;
            }
          }
          .divInputPuntoDeToma {
            width: 70%;
            .input {
              width: 100%;
            }
          }
          .divInputAnalisis{
           width: 70%;
            .input {
              width: 100%;
            } 
          }
          .divInputIntervencion {
            width: 44%;
            .input {
              width: 100%;
            }
          }
        }
        .divInputt {
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .divInputEstado {
            width: 30%;
            .input {
              width: 100%;
            }
          }
        }
        .divInputtt {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .divInputMensaje {
            width: 100%;
            .input {
              width: 100%;
            }
          }
        }
      }
      .columnaDos {
        width: 55%;
        padding-top: 10px;
      }
    }
  }
  .nuevoAnalisis{
    .formulario{
      .divInputs{
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  //Importaciones
  .importaciones {
    .divInputs {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 30px;
    }
    .divUnoInput {
      display: flex;
      justify-content: space-between;
      .divInputImportaciones {
        width: 40%;
        .inputImportaciones {
          width: 100%;
        }
      }
    }
    .divDosInput {
      .checkbox {
        padding: 10px;
      }
      .dropzone {
        width: 50%;
      }
    }
  }
  //CargaResultados
  .cargaResultados {
    .divInputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 0px;
    }
    .divUnoInput {
      display: flex;
      gap: 20px;
      .divInput {
        width: 50%;
        .input {
          width: 100%;
        }
      }
    }
    .divDosInput {
      .divInput {
        width: 100%;
        .input {
          width: 100%;
        }
      }
    }
    .divTresInput {
      display: flex;
      gap: 20px;
      align-items: center;
      .divInputsTres {
        display: flex;
        gap: 10px;
      }
      .divLabelInputTres {
        display: flex;
        flex-direction: column;
        .label {
          font-family: ${fonts.primary};
          font-weight: 400;
          font-size: 12px;
        }
        .span {
          font-family: ${fonts.primary};
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .ordenesDeTrabajoPanelControl {
      .formularioPanelControl {
        display: flex;
        flex-direction: column;
      }
      .divColumnaUno,
      .divColumnaDos {
        width: 100%;
      }
      .styledTables {
        height: auto;
      }
    }
    .importaciones {
      .divUnoInput {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .divInput {
          width: 100%;
        }
      }
      .dropzone {
        width: 90% !important;
      }
    }
    .muestras {
      .columna {
        .a,
        .b,
        .c,
        .d,
        .e,
        .f {
          flex-direction: column;
          .input {
            width: 100%;
          }
          .divInput {
            width: 100%;
          }
        }
        .c {
          .MuiStack-root {
            width: 100%;
          }
        }
      }
    }
    .diagMuestras{
      .formulario{
        flex-direction: column;
        .columnaUno,.columnaDos{
        width: auto;
        }
      }
    }
  }
`;
export const StyledModalProveedores = styled.div`
  width: 60%;
  height: auto;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 50px;
  fieldset {
    border-radius: 8px;
  }
  .proveedores {
    .divLabel {
      background-color: ${colors.lightGray};
      border-radius: 10px;
      padding: 5px;
      max-width: 100%;
      text-align: center;
      .titulo {
        font-size: 24px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        margin: 0px;
        font-weight: bold;
      }
    }
    .inputCodigo {
      width: 50%;
    }
    .inputDenominacion {
      width: 100%;
    }
    .inputDireccion {
      width: 100%;
    }
    .divUltimoInputs {
      display: flex;
      .divInput:nth-child(1) {
        width: 65%;
      }
      .divInput:nth-child(2) {
        width: 35%;
      }
      .inputEmail {
        width: 90%;
      }
      .inputTelefono {
        width: 100%;
      }
      display: flex;
      justify-content: space-between;
    }
  }
  .divInputsItem {
    padding: 40px 0px 0px 0px;
    .formulario {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
  .divButtonsItem {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: auto;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
    .divInput,
    .input {
      width: 100% !important;
    }
    .divUltimoInputs,
    .divs {
      flex-direction: column;
      gap: 40px;
    }
    .dropzone {
      width: 90%;
    }
  }
`;

//Clientes Externos
export const StyledModalcomponentClientesExternos = styled(Box)`
  height: auto;
  position: absolute;
  z-index: 999999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 80%;
  background-color: #ffffff;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  fieldset {
    border-radius: 8px;
  }
  .divLabel {
    background-color: ${colors.lightGray};
    border-radius: 10px;
    padding: 5px;
    max-width: 100%;
    text-align: center;
    .titulo {
      font-size: 24px;
      color: ${colors.blueDark};
      font-family: ${fonts.primary};
      margin: 0px;
      font-weight: bold;
    }
  }
  .divButtonsItem {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: auto;
  }
  .infoMuestras {
    .formulario {
      padding: 20px 0px;
    }
    .divInputs {
      display: flex;
      flex-direction: column;

      gap: 10px;
      .divUnoInput,
      .divDosInput {
        display: flex;
        gap: 5px;
        .divInput {
          width: 33%;
          .input {
            width: 100%;
          }
        }
      }
    }
  }
  .intervencionesClientesExternos {
    display: flex;
    align-items: baseline;
    padding: 20px 0px;
    gap: 10px;
    .boxInputs {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 70%;
      .divInput,
      .inputDefault {
        width: 100%;
      }
    }
    .boxTable {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      gap: 10px;
      border: 2px solid #afccd5;
      opacity: 0px;
      .titulo {
        font-size: 24px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        margin: 0px;
        font-weight: bold;
        text-align: center;
      }
      .styledTables {
        height: 40vh;
        .MuiTableContainer-root {
          height: 90%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .intervencionesClientesExternos {
      flex-direction: column;
      .boxInputs {
        width: 100%;
      }
      .boxTable {
        width: 100%;
      }
    }
  }
`;
//Nav
export const StyledModalNav = styled(Box)`
  display: flex;
  top: 10%;
  max-width: 995px;
  max-height: 70%;
  overflow-y: auto;
  background-color: #dbe5e8;
  flex-wrap: wrap;
  position: fixed;
  border-radius: 8px;
  border: 4px solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  gap: 15px;
  .divHeaderModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .divInput {
    width: 50%;
  }
  .divIconCerrar {
    position: absolute;
    top: 20px;
    right: 20px;
    .iconCerrar {
      cursor: pointer;
    }
  }
  .divIcon {
    img {
      width: 40px;
      height: 40px;
    }
  }
  .buttonss {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    padding: 10px;
  }
  .divButtonModalIcon {
    display: flex;
    gap: 10px;
    width: 45%;
  }
  .divLabel {
    display: flex;
    flex-direction: column;
    .label {
      font-family: ${fonts.primary};
      color: ${colors.blueDark};
      font-size: 15px;
      font-weight: 600;
      text-align: start;
      padding: 0px;
    }
    .sub {
      color: ${colors.darkGrayDos};
      font-family: ${fonts.primary};
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
    }
  }
  @media (max-width: 768px) {
    overflow-y: auto;
    padding: 10px 5px;
    top: 10%;
    .buttonss {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .divButtonModalIcon {
      width: 100%;
    }
    .divIcon {
      img {
        width: 20px;
        height: 20px;
      }
    }
    .divInput {
      width: 80%;
    }
  }
`;


