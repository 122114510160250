import React, { useReducer, useState, useEffect } from 'react'
import CustomButton from '../../../../Button/CustomButton.tsx';
import Input from '../../../../Input/Input.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';
import Toast from '../../../../Toast/Toast.jsx';
import { StyledModalcomponent } from '../../../Styled';
import { FormControlLabel, Switch } from '@mui/material';
import { createCausasDeFallas, updateCausasDeFallas, getTipoFallas } from '../../../../../api/Configuracion.js';

const CausasDeFallasModal = (props) => {
  const propsTipoDeFallas = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsTipoDeFallas(props.datos);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? "",
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    tipoFalla: datos.tipoId ?? "",
    habilitado: datos.habilitado === "ACTIVE" || datos.habilitado === "SI" ? true : false
  });
  const [formCompleto, setFormCompleto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoUnidades, setTipoFallas] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");


  useEffect(() => {
    const fetchTipoUnidades = async () => {
      const res = await getTipoFallas();
      const unidades = res?.data?.items.map((item) => ({
        value: item.id,
        label: item.desc_tpfalla,
      }));
      setTipoFallas(unidades);
      if (datos && (editar || ver)) {
        const matchOption = unidades.find(
          (option) => option.value === datos.tipoId
        );
        if (matchOption) {
          setSelectedOption(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }
    };
    fetchTipoUnidades();
  }, []);

 useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);
    const camposaValidar = ["codigo", "descripcion", "tipoFalla", "habilitado"];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const titulo = () => {
    const titulo = ver ? "Ver causas de falla" : editar ? "Editar causas de falla" : "Crear o editar causas de falla";
    return titulo
  }
  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formatterForm = {
      id: form.id,
      codigo_falla: form.codigo.toString(),
      desc_falla: form.descripcion,
      tipo_falla_id: parseInt(form.tipoFalla),
      created_by: "dasfdasd",
      created_date: new Date(),
      last_modified_by: "1",
      last_modified_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };
    if (editar) {
      updateCausasDeFallas(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Unidad modificada con exito",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo editar la Unidad",
            status: "error",
          });
        });
    } else {
      createCausasDeFallas(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({ msj: "Unidad creada con exito", status: "success" });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({ msj: "No se pudo crear la unidad", status: "error" });
        });
    }
  };
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponent>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <form className="formulario" onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div className="causasDeFalla">
          <div className="divLabel">
            <p className="titulo"> {titulo()}</p>
          </div>
          <div className="Ainput">
            <Input
              name="codigo"
              label="codigo"
              type="text"
              placeholder="Código"
              variant="outlined"
              className="input"
              value={form.codigo}
              onChange={handleChange}
              disabled={ver && true}
            />
            <FormControlLabel
              control={<Switch
                name="habilitado"
                disabled={ver}
                checked={form.habilitado}
                onChange={handleChange}
                value={form.habilitado}
              />}
              label="Habilitar"
            />
          </div>
          <div className="Binput" style={{ flexDirection: "column", gap: "20px" }}>
            <Input
              name="descripcion"
              label="descripcion"
              type="text"
              placeholder="Descripción"
              variant="outlined"
              className="input descripcion"
              value={form.descripcion}
              onChange={handleChange}
              disabled={ver && true}
            />
             <Input
              reactSelect
              options={tipoUnidades}
              value={selectedOption}
              onChange={(e) => {
                handleChange(e, "tipoFalla");
              }}
              isDisabled={ver && true}
              placeholder="Selecciona el tipo"
            />
          </div>
        </div>
        <div className="divButtonsItem" style={{ margin: "auto" }}>
          {renderButtons()}
        </div>
      </form>

    </StyledModalcomponent>
  )
}

export default CausasDeFallasModal