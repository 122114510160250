import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  deleteComponente,
  getComponentesPaginated,
  getComponenteSearch,
} from "../../../../api/Configuracion";
import Modal2 from "../../../../components/Modal/Modal";
import Toast from "../../../../components/Toast/Toast";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const Componentes = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [componentes, setComponentes] = useState([]);
  const [componenteIdId, setComponenteId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [key, setKey] = useState(1);

  useEffect(() => {
    getComponentesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setComponentes(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_com,
            descripcion: res.desc_com,
            habilitado: res.status,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    getComponentesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.items.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setComponentes(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_com,
            descripcion: res.desc_com,
            habilitado: res.status,
          };
        })
      );
    });
  }, [orderBy, paginaActual]);

  useEffect(() => {
    if (searchTerm === "") {
      getComponentesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
        setPageCount(res.data.items.totalPages);
        setItemsCount(res.data.totalItems);
        // alert(JSON.stringify(res.data))
        setComponentes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_com,
              descripcion: res.desc_com,
              habilitado: res.status,
            };
          })
        );
      });
    } else {
      getComponenteSearch("", searchTerm).then((res) => {
        setPaginaActual(1);
        setPageCount(res.data.items.totalPages);
        setItemsCount(res.data.totalItems);
        // alert(JSON.stringify(res.data))
        setComponentes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_com,
              descripcion: res.desc_com,
              habilitado: res.status,
            };
          })
        );
      });
    }
  }, [searchTerm]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setComponenteId(valueRow.id);
      setModalStatus2(true);
      //handleDeleteComponente(valueRow.id)
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDeleteComponente = (id) => {
    deleteComponente(id)
      .then(() => {
        setComponenteId("");
        setMsjToast("Tipo de de Lubricante eliminado con exito");
        let paginaActualTemp = paginaActual;
        if (Componentes.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getComponentesPaginated(paginaActualTemp, orderBy, searchTerm);
      })
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Componente eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setComponentes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_com,
                descripcion: res.desc_com,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        setMsjToast("Error al eliminar cOMPONENTE");
        setError(true);
      });
  };
  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success && (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        )}

        {error && (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        )}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key+1)
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
          getComponentesPaginated(paginaActual, orderBy, searchTerm).then(
            (res) => {
              setPageCount(res.data.totalPages);
              setItemsCount(res.data.totalItems);
              setComponentes(
                res.data.items.map((res) => {
                  return {
                    id: res.id,
                    codigo: res.codigo_com,
                    descripcion: res.desc_com,
                    habilitado: res.status,
                  };
                })
              );
            }
          );
        }}
        type={"componentes"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Componentes"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo componente"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={componentes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteComponente(componenteIdId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Componentes;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
