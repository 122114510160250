import React, { useState, useEffect } from "react";
import Switch from "../../assets/Icons/Buttons/Table/Switch.svg";
import {
  Styled,
  TableContainerStyled,
  TableStyled,
  TableBody,
  TableCellStyled,
  TableHead,
  LabelHeadColumn,
  TableRow,
  PaginationStyled,
  DivButtonsTable,
  IconButton,
  Tooltip,
  RenderImgStyled,
  TablePaginationStyled,
} from "./TablesStyled";
import { Height } from "@mui/icons-material";

const columnsDefaultCfg = {
  type: {
    //clientes
    clientes: [
      { id: "codigo", label: "Código" },
      { id: "denominacion", label: "Denominación" },
      { id: "email", label: "EMail" },
      { id: "telefono", label: "Teléfono" },
      { id: "logo", label: "Logo" },
      { id: "buttons" },
    ],
  },
};
const rowsDefault = {
  clientes: [
    {
      codigo: "2822",
      denominacion: "LOVE CLIP",
      email: "loveclip@gmail.com",
      telefono: "035147903269",
      // logo: Cliente,
      tipo: "tipo",
      cliente: "cliente 2",
      tagplanta: "tag planta",
      planta: "planta 1",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 1",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
    {
      codigo: "2822",
      denominacion: "Cliente 1",
      email: "Nombre@proveedor ",
      telefono: "035147903269",
      // logo: ClienteDos,
      tipo: "tipo",
      cliente: "cliente 1",
      tagplanta: "tag planta",
      planta: "planta 2",
      lubricante: "aceite 1",
      componente: "componente 1",
      equipo: "equipo 2",
      fecha: "11/12/2023",
      tipointervencion: "tipo",
      intervencion: "cambio de aceite",
    },
  ],
};

const Tables = (props) => {
  const propsTables = (datosTables) => {
    const renderColumns = () => {
      let type = null;
      if (typeTable === "clientes") {
        type = columnsDefaultCfg.type[columnNameFromProps ?? []];
      }
      return type;
    };

    const renderRows = () => {
      let type = null;
      if (typeTable === "clientes") {
        type = rowsDefault["clientes"];
      }
      return type;
    };

    const cRows = datosTables?.cRows ?? 10;
    const columnNameFromProps = datosTables?.table ?? "clientes";
    const typeTable = datosTables?.typeTable || "clientes";
    const columns = datosTables.columns ? datosTables.columns : renderColumns();
    const rows = datosTables?.rows ? datosTables?.rows : renderRows();
    const pagination = datosTables?.pagination || false;
    const valueBuscador = datosTables?.valueBuscador || "";
    const buttonsTable = datosTables?.buttons || null;
    const onClickButtonTable = datosTables?.onClickButtonTable || "nada";
    const onClickSwitch = datosTables?.onClickSwitch ?? null;
    const tableColor = datosTables?.tableColor ?? false;
    return {
      cRows,
      columns,
      rows,
      pagination,
      typeTable,
      valueBuscador,
      buttonsTable,
      onClickButtonTable,
      onClickSwitch,
      tableColor,
    };
  };
  
  const {
    cRows,
    columns,
    rows,
    pagination,
    typeTable,
    valueBuscador,
    buttonsTable,
    onClickButtonTable,
    onClickSwitch,
    tableColor,
    totalRows,
  } = propsTables(props);

  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(cRows);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(rows);
  const [key, setKey] = React.useState(0);

  useEffect(() => {
    handleChangePage("", props.actualpage - 1);
  }, [props.actualpage]);

  const handleChangePage = (event, newPage) => {
    if (props.page) {
      props.page(newPage);
    }
    setPage(newPage);
  };
  const rendersButtonTable = (value) => {
    const render = buttonsTable?.map((res, i) => {
      return (
        <Tooltip
          title={res.titleToolTip}
          arrow
          placement="top"
          className="tool"
          key={i}
        >
          <IconButton
            aria-label="fingerprint"
            color="default"
            size="small"
            className={res.className}
            onClick={() => {
              onClickButtonTable(res.titleToolTip, value);
            }}
          >
            {res.icon}
          </IconButton>
        </Tooltip>
      );
    });
    return render;
  };
  const renderDatosRows = (value, columnId, row) => {
    if(value === ","){
      return // refactorizar esto;
    }
    const rowClientes = () => {
      return value;
    };
    const rowProp = () => {
      return columnId === "buttons" ? (
        <DivButtonsTable>{rendersButtonTable(row)}</DivButtonsTable>
      ) : (
        value
      );
    };
    return (
      <>
        {typeTable === "clientes" && rowClientes()}
        {typeTable === "prop" && rowProp()}
      </>
    );
  };

  useEffect(() => {
    const normalizedValue = valueBuscador
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/,/g, "");
    const lowercasedValue = normalizedValue.toLowerCase();

    setResultadosBusqueda(() => {
      const rowsToSearch = rows;
      if (!lowercasedValue) {
        return rowsToSearch;
      }
      return rowsToSearch?.filter((item) =>
        Object.values(item).some(
          (field) =>
            typeof field === "string" &&
            field
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              //.includes(lowercasedValue)
              .startsWith(lowercasedValue)
        )
      );
    });
  }, [valueBuscador, rows]);

  return (
    <Styled className="styledTables" key={key}>
      <TableContainerStyled>
        <TableStyled stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCellStyled
                  key={column.id}
                  align={column.align}
                  className="headTable"
                >
                  <LabelHeadColumn>
                    {column.label}
                    {column.id !== "buttons" && (
                      <RenderImgStyled
                        img={Switch}
                        onClick={() => {
                          if (onClickSwitch) {
                            onClickSwitch(column.label);
                          }
                        }}
                      />
                    )}
                  </LabelHeadColumn>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {resultadosBusqueda?.map((row, rowIndex) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={rowIndex}
                  sx={{
                    backgroundColor: rowIndex % 2 !== 1 ? "#ffffff" : "#aebec391",
                    "&:hover": {
                      backgroundColor: "#aebec391 !important",
                    },
                  }}
                >
                  {columns?.map((column) => {
                    let value = row[column.id];
                    let estilo;
                    if (column.label === "Color") {
                      estilo = { backgroundColor: row[column.id] };
                    } else if (column.label === "Logo") {
                      estilo = { Height: "30px" };
                    } else {
                      estilo = { backgroundColor: "transparent" };
                    }

                    return (
                      <TableCellStyled
                        style={estilo}
                        key={column.id}
                        align={column.align}
                      >
                        {["Logo", "Imagen"].includes(column.label) && value && value!==","  ? (
                          <img alt="img" className="logocelda" src={value} />
                        ) : (
                          renderDatosRows(value, column.id, row)
                        )}
                      </TableCellStyled>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableStyled>
      </TableContainerStyled>
      {/*pagination ?
        <PaginationStyled
          count={props.pages ? props.pages : Math.ceil(rows?.length / rowsPerPage)}
          page={page + 1}
          onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          showFirstButton
          showLastButton
          
          shape="rounded"
        />
        : null*/}
      {pagination ? (
        <TablePaginationStyled
          key={props.totalRows}
          count={props.totalRows} //
          page={page}
          onPageChange={(event, newPage) => handleChangePage(event, newPage)}
          showFirstButton
          showLastButton
          rowsPerPage={20}
          shape="rounded"
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count, page }) =>
            `Mostrando  ${from}-${to} de ${count} Elementos`
          }
        />
      ) : null}
    </Styled>
  );
};

export default Tables;
