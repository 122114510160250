import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Styled, StyledTypography } from './TiposDefallaStyled';
import { Search } from '@mui/icons-material';
import { RenderImgStyled } from '../../../Task/Items/SuperAdminStyled';
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import Input from "../../../../components/Input/Input";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import More from "../../../../assets/Icons/Buttons/More.svg";
import Tables from "../../../../components/Tables/Tables";
import Modal from "../../../../components/Modal/Modal.jsx";
import Modal2 from "../../../../components/Modal/Modal.jsx";
import Toast from '../../../../components/Toast/Toast.jsx';
import {
  getTipoFallasSearch,
  getTipoFallaPaginated,
  deleteTipoFallas,
} from '../../../../api/Configuracion.js';

const columnsDefaultCfg = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
]

const TiposDeFalla = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tiposDefalla, setTiposDeFalla] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [key, setKey] = useState(1);
  const [tiposDeFallaId, setTiposDeFallaId] = useState("");

  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      setModalStatus(true);
    } else {
      setDatosModal("");
      setTiposDeFallaId(valueRow.id)
      setModalStatus2(true);
    }
    if (value === "Crear") {
      setDatosModal("");
      setModalStatus(true);
    }
  }
  //Datos al modal
  useEffect(() => {
    getTipoFallaPaginated(paginaActual, orderBy, searchTerm)
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTiposDeFalla(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tpfalla,
              descripcion: res.desc_tpfalla,
              habilitado: res.status,
            };
          })
        );
      })
      .catch((err) => {
        console.error("Error al obtener tipos de fallas:", err);
      });
  }, [paginaActual, orderBy]);
  //Buscador
  console.log(searchTerm);
  useEffect(() => {
    if (searchTerm === "") {
      getTipoFallaPaginated(paginaActual, orderBy, searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTiposDeFalla(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tpfalla,
                descripcion: res.desc_tpfalla,
                habilitado: res.status,
              };
            })
          );
        })
        .catch((err) => {
          console.error("Error al obtener los tipos de falla:", err);
        });
    } else {
      getTipoFallasSearch(searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTiposDeFalla(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tpfalla,
                descripcion: res.desc_tpfalla,
                habilitado: res.status,
              };
            })
          );
        })
        .catch((err) => {
          console.error("Error al obtener los Clientes:", err);
        });
    }
  }, [searchTerm]);

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };
  const handleDeleteTiposDeFalla = (tiposDeFallaId) => {
    deleteTipoFallas(tiposDeFallaId)
      .then(() => {
        setTiposDeFallaId("");
        let paginaActualTemp = paginaActual;
        if (tiposDefalla.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getTipoFallaPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm
        );
      })
      .then((res) => {
        // setKey(key + 1);
        setMsjToast("Tipo de falla eliminado con exito.");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTiposDeFalla(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tpfalla,
                descripcion: res.desc_tpfalla,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        // setKey(key + 1);
        setMsjToast("Error al eliminar tipo de falla.");
        setError(true);
      });
  };
  return (
    <Styled key={key}>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status, newItem }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setMsjToast(msj);
            setSuccess(true);

            if (newItem) {
              setTiposDeFalla((prev) => [...prev, newItem]);
            }

            getTipoFallaPaginated(paginaActual, orderBy, searchTerm)
              .then((res) => {
                setPageCount(res.data.totalPages);
                setItemsCount(res.data.totalItems);
                setTiposDeFalla(
                  res.data.items.map((res) => ({
                    id: res.id,
                    codigo: res.codigo_tpfalla,
                    descripcion: res.desc_tpfalla,
                    habilitado: res.status,
                  }))
                );
              });
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
        type={"tiposDeFallas"}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTiposDeFalla(tiposDeFallaId);
        }}
      />

      <StyledTypography>Tipos De Falla</StyledTypography>

      <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            className="inputComponent"
            value={searchTerm}
            search
            isClearable={() => { setSearchTerm("") }}
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nuevo tipo de falla"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => { handleClickButtonTable("crear") }}
        />
      </Box>
      <Tables
        typeTable={"prop"}
        columns={columnsDefaultCfg}
        rows={tiposDefalla}
        cRows={10}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        tableColor={false}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButtonTable(value, valuesRow);
        }}
      />

    </Styled>

  )
}

export default TiposDeFalla