import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  getUnidades,
  getUnidadesByTenantId,
  getUnidadesPaginated,
  deleteUnidad,
  getUnidadesSearch,
} from "../../../../api/Configuracion";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "simbolo", label: "Símbolo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    tipo: "Tipo",
    simbolo: "Símbolo",
    habilitado: "Habilitado",
  },
];
const Unidades = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [unidadId, setUnidadId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortTerm, setSortTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [key, setKey] = useState(1);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    getUnidadesPaginated(1, "", "")
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setRows(
          res.data.items.map((item) => {
            return {
              id: item.id,
              codigo: item.codigo_uni,
              descripcion: item.desc_uni,
              tipo: item.tipo_uni.description,
              tipoId: item.tipo_uni.id,
              simbolo: item.simbolo_uni,
              habilitado: item.status,
            };
          })
        );
        console.log(res)
      })
      .catch((err) =>
        console.error("Error al obtener datos de allunidades:", err)
      );
  }, []);

  useEffect(() => {
    getUnidadesPaginated(paginaActual, orderBy, searchTerm)
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setRows(
          res.data.items.map((item) => {
            return {
              id: item.id,
              codigo: item.codigo_uni,
              descripcion: item.desc_uni,
              tipo: item.tipo_uni.description,
              tipoId: item.tipo_uni.id,
              simbolo: item.simbolo_uni,
              habilitado: item.status,
            };
          })
        );
      })
      .catch((err) =>
        console.error("Error al obtener datos de allunidades:", err)
      );
  }, [paginaActual, orderBy]);

  useEffect(() => {
    if (searchTerm === "") {
      getUnidadesPaginated(paginaActual, orderBy, searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setRows(
            res.data.items.map((item) => {
              return {
                id: item.id,
                codigo: item.codigo_uni,
                descripcion: item.desc_uni,
                tipo: item.tipo_uni.description,
                tipoId: item.tipo_uni.id,
                simbolo: item.simbolo_uni,
                habilitado: item.status,
              };
            })
          );
        })
        .catch((err) =>
          console.error("Error al obtener datos de allunidades:", err)
        );
    } else {
      setPaginaActual(1);
      getUnidadesSearch("", "", searchTerm)
        .then((res) => {
          if (res.data && res.data.items && res.data.items.length > 0) {
          setPageCount(1);
          setItemsCount(res.data.totalItems);
          setRows(
            res.data.items.map((item) => {
              return {
                id: item.id,
                codigo: item.codigo_uni,
                descripcion: item.desc_uni,
                tipo: item.tipo_uni.description,
                tipoId: item.tipo_uni.id,
                simbolo: item.simbolo_uni,
                habilitado: item.status,
              };
            })
          );
        }else {
          setRows([]);
          setPageCount(0);
          setItemsCount(0);
        }
        })
        .catch((err) =>
          console.error("Error al obtener datos de allunidades:", err)
        );
    }
  }, [searchTerm]);

  const handleDeleteUnidad = (unidadId) => {
    //este ver
    deleteUnidad(unidadId)
      .then(() => {
        setUnidadId("");
        // "preguntar por size y cambiar de pagina"
        let paginaActualTemp = paginaActual;
        if (rows.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getUnidadesPaginated(
          paginaActualTemp,
          "descripcion",
          searchTerm
        );
      })
      .then((res) => {
        //setKey(key + 1); // este hay que agregar arriba
        setMsjToast("Unidad eliminada con exito.");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setRows(
            res.data.items.map((item) => {
              return {
                id: item.id,
                codigo: item.codigo_uni,
                descripcion: item.desc_uni,
                tipo: item.tipo_uni.description,
                tipoId: item.tipo_uni.id,
                simbolo: item.simbolo_uni,
                habilitado: item.status,
              };
            })
          );
        }, 1000);
      })
      .catch((err) => {
        //pasar a la otra pantalla
        //setKey(key + 1);
        setMsjToast("Error al eliminar la Unidad.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      //alert("Editar: "+ JSON.stringify(valueRow));
      setModalStatus(true);
      setDatosModal({ button: value, datos: valueRow });
    } else {
      // alert("Eliminar: "+ JSON.stringify(valueRow));
      setUnidadId(valueRow.id);
      setModalStatus2(true);
      //  handleDeleteUnidad(valueRow.id)
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  return (
    <Styled key={key}>
      <div classname="toast">
        {success && (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        )}
        {error && (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        )}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          //este tamb se migra a la otra pantalla
          setSuccess(false);
          setError(false);
          if (status === "success") {
            //setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
           // setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
          getUnidadesPaginated(paginaActual, "desc_uni", searchTerm).then(
            (res) => {
              setPageCount(res.data.totalPages);
              setItemsCount(res.data.totalItems);
              setRows(
                res.data.items.map((item) => {
                  return {
                    id: item.id,
                    codigo: item.codigo_uni,
                    descripcion: item.desc_uni,
                    tipo: item.tipo_uni.description,
                    tipoId: item.tipo_uni.id,
                    simbolo: item.simbolo_uni,
                    habilitado: item.status,
                  };
                })
              );
              //hacer una funcion de reload
            }
          );
        }}
        type={"unidades"}
        // iconX={valueTicket === "Ver"}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Unidades"
        value={searchTerm}
        valueBuscador={(value) => setSearchTerm(value)}
        isClearable={()=>{setSearchTerm("")}}
        labelButton={"Nueva unidad"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={rows}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteUnidad(unidadId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Unidades;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  min-height: 100vh;
  .styledTables {
    //height: 100vh;
  }
`;
