import React, { useState, useEffect, useReducer } from "react";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Tables from "../../../../Tables/Tables.jsx";
// import DropZone from "../../../../DropZone/DropZone.jsx";
import DropZoneTest from "../../../../DropZone/DropZoneTest.jsx";
import RenderImg from "../../../../RenderImg/RenderImg.tsx";
import Modal from "../../../Modal.jsx";
import More from "../../../../../assets/Icons/Buttons/More.svg";
import Toast from "../../../../Toast/Toast";
import Modal2 from "../../../Modal.jsx";
import Preloader from "../../../../Preloader/Preloader.jsx";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  buttonsDefault,
  buttonsDefaultver,
} from "../../../../ButtonsTable/ButtonsTable.js";
import { StyledEstadosFinalesTables } from "../../../Styled.jsx";
import {
  updateEstadosFinales,
  createEstadosFinales,
  getMensagesPredefinidosPaginated,
  getMensagesPredefinidosPaginated2,
  deleteMensagesPredefinidos,
  getEstasdosFinalesbyId,
  getMensagesPredefinidosbyestado,
  getTemplateEmailOptions,
} from "../../../../../api/Configuracion";

const columns = [
  { id: "nombre", label: "Nombre" },
  { id: "sugerencia", label: "Sugerencia" },
  { id: "diagnostico", label: "Diagnóstico" },
  { id: "buttons" },
];

const EstadosFinalesModal = (props) => {
  const [formCompleto, setFormCompleto] = useState(false);
  const [estadosFinalesId, setEstadosFinalesId] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [mensajesPredefinidos, SetMensajesPredefinidos] = useState();
  const [mensajesPredefinidosId, SetMensajesPredefinidosId] = useState("");
  const [estadosCargados, setEstadosCargados] = useState(false);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [imagen_efm, setImagen_efm] = useState(["", ""]);
  const [key, setKey] = useState(1);
  const [emailOptions, setEmailOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const propsUnidades = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsUnidades(props.datos);

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      orden: datos.orden ?? "",
      abreviatura: datos.abreviatura ?? "",
      descripcion: datos.descripcion ?? "",
      color: datos.color ?? "",
      imagen: datos.imagen,
      codigo_template_email: datos.codigo_template_email ?? "",
      //imagen_efm: datos.imagen_efm,
      //contentType: datos.imagen_efm_content_type,
      habilitado:
        datos.habilitado === "SI" || datos.habilitado === "ACTIVE"
          ? true
          : false,
    }
  );

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);

    const camposaValidar = [
      "codigo",
      "descripcion",
      "color",
      "abreviatura",
      "orden",
      // "codigo_template_email",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  useEffect(() => {
    if (datos.imagen !== "" && datos.imagen !== undefined) {
      let auxbase65 = datos.imagen.split(",");
      setImagen_efm([auxbase65[0], auxbase65[1]]);
    }

    if ((form && form.id) || estadosFinalesId !== "") {
      fetchMensajesPaginated(1, "", form.id, "fist");
    }

    const fetchAllEmailOptions = async () => {
      const res = await getTemplateEmailOptions();
      // console.log("res: ", res);
      const templates = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_t_em,
      }));
      setEmailOptions(templates);

      if (datos) {
        const matchOption = templates.find(
          (option) => option.value.toString() === datos.codigo_template_email
        );
        if (matchOption) {
          setSelectedOption(matchOption);
        }
        // else if (templates.length > 0 && editar) {
        //   setSelectedOption(templates[0]);
        // }
      } else if (templates.length > 0 && editar) {
        setSelectedOption(templates[0]);
      }
    };
    fetchAllEmailOptions();

    /*getMensagesPredefinidosPaginated2(
      paginaActual,
      orderBy,
      searchTerm,
      99
    ).then((res) => {
      let mensajes = [];
      if ((form && form.id) || estadosFinalesId !== "") {
        //  alert("tiene form id o esgtadoninalid "+ form.id + " / "+estadosFinalesId); ;
        mensajes = res.data.items
          .filter((res) => {
            if (editar) {
              return res.estado_id.id === form.id;
            } else {
              return res.estado_id.id === form.id ? form.id : estadosFinalesId;
            }
            //return res.estado_id.id === form.id;
          })
          .map((res) => {
            // alert(JSON.stringify(res))
            return {
              id: res.id,
              nombre: res.nombre_m_pre,
              sugerencia: res.sugerencia_m_pre,
              diagnostico: res.diagnostico_m_pre,
              
            };
          });
      } else {
        // alert("tiene form id o esgtadoninalid ");
        mensajes = [];
      }
      //alert(JSON.stringify(mensajes))
      SetMensajesPredefinidos(mensajes);
      setPageCount(res.data.totalPages);
      setItemsCount(mensajes.length); //setItemsCount(res.data.totalItems);
    });*/
  }, []);

  //hace useefect de http://localhost:9000/allMensajePredefinidos/estado/1
  useEffect(() => {
    if (datos.id) {
      getEstasdosFinalesbyId(datos.id).then((res) => {
        setEstadoSeleccionado(res.data);
        //console.log("estado: " + JSON.stringify(res.data));
      });
    }
  }, [estadosFinalesId]);

  const fetchMensajesPaginated = async (pagina, order, estado, action) => {
    const res = await getMensagesPredefinidosbyestado(estado);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    }

    if (action === "new" && mensajesPredefinidos.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Equipo eliminado con exito.");
      setSuccess(true);
    }

    SetMensajesPredefinidos(
      res.data.items.map((res) => {
        return {
          id: res.id,
          nombre: res.nombre_m_pre,
          sugerencia: res.sugerencia_m_pre,
          diagnostico: res.diagnostico_m_pre,
        };
      })
    );
  };

  const handleChange = (e, nameReactSelect) => {
   if(!e){
    setSelectedOption(null);
    setForm({ type: "SET_FIELD", field: nameReactSelect, value: "" });

   }else{
    if(e.target){
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    }else {
      const value = e.value;
      const name = nameReactSelect;
      const label = e.label;
      setSelectedOption(e);
      setForm({ type: "SET_FIELD", field: name, value: value });
    }

   }
    if (ver) {
      return;
    }
 
  };
  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      SetMensajesPredefinidosId(valueRow.id);
      setModalStatus2(true);
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDeleteMensajes = (id) => {
    deleteMensagesPredefinidos(id)
      .then(() => {
        SetMensajesPredefinidosId("");
        fetchMensajesPaginated(paginaActual, "", form.id, "delete");
        /* return getMensagesPredefinidosPaginated(
          paginaActual,
          orderBy,
          searchTerm
        ).then((res) => {
          let mensajes = [];
          if (form && form.id) {
            mensajes = res.data.items
              .filter((res) => {
                return res.estado_id.id === form.id;
              })
              .map((res) => {
                // alert(JSON.stringify(res))
                return {
                  id: res.id,
                  nombre: res.nombre_m_pre,
                  sugerencia: res.sugerencia_m_pre,
                  diagnostico: res.diagnostico_m_pre,                  
                };
              });
          } else {
            mensajes = [
              {
                id: "",
                codigo: "",
                descripcion: "",
                habilitado: "",
                abreviatura: "",
              },
            ];
          }
          SetMensajesPredefinidos(mensajes);
          setPageCount(res.data.totalPages);
          // setItemsCount(res.data.totalItems);
          setItemsCount(mensajes.length);
        });*/
      })
      .catch((err) => {
        console.error("Error al eliminar el Mensaje Predefinido:", err);
        setIsLoading(false);
        setMsjToast("Error al eliminar el Mensaje Predefinido");
        setError(true);
      });
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!estadosCargados && !ver && (
          <CustomButton
            label="Confirmar"
            variant="contained"
            className="buttonColorDefault btn"
            type="submit"
            disabled={!formCompleto}
          />
        )}
        {estadosCargados && (
          <CustomButton
            label="Cerrar"
            variant="contained"
            className="buttonColorDefault btn"
            onClick={props.onClick}
            disabled={false}
          />
        )}
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      codigo_efm: form.codigo,
      desc_efm: form.descripcion,
      abrev_efm: form.abreviatura,
      orden_efm: form.orden,
      color_efm: form.color,
      codigo_template_email_efm: form.codigo_template_email.toString(),
      imagen_efm: imagen_efm[1],
      imagen_efm_content_type: imagen_efm[0],
      diagnostico_efm: "Diagnóstico de la muestra",
      sugerencias_efm: "Sugerencias para la muestra",
      created_by: "admin",
      created_date: new Date(),
      last_modified_by: "admin",
      last_modified_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      eliminado: false,
      tenantId: 1,
    };

    if (editar) {
      formattedData.id = form.id;
    }

    if (editar) {
      updateEstadosFinales(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Estado modificado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo editar el estado.",
            status: "error",
          });
        });
    } else {
      createEstadosFinales(formattedData)
        .then((res) => {
          setIsLoading(false);
          setMsjToast("Estado Creado con exito");
          setSuccess(true);
          setEstadosFinalesId(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          setMsjToast("No se pudo crear el Estado");
          setError(true);
          setIsLoading(false);
          /* props.onClick({
            msj: "No se pudo crear el estado.",
            status: "error",
          });*/
        });
    }
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver estados finales"
      : editar
      ? "Editar estado final"
      : "Crear o editar estados finales";
    return titulo;
  };

  return (
    <StyledEstadosFinalesTables style={{ padding: "20px", height: "auto" }}>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div className="toast" style={{ zIndex: "1000", left: "0px" }}>
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <div className="estadosFinales">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <div className="divBody">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="divColumnas">
              <div className="divColumnaUno">
                <Input
                  value={form.codigo}
                  label="Código"
                  className="input inputCodigo"
                  variant="outlined"
                  size={"small"}
                  name="codigo"
                  onChange={handleChange}
                  disabled={ver || estadosCargados}
                  // onChange={handleChange}
                />
                <Input
                  value={form.orden}
                  label="Orden"
                  className="input inputOrden"
                  variant="outlined"
                  size={"small"}
                  name="orden"
                  onChange={handleChange}
                  disabled={ver || estadosCargados}
                  // onChange={handleChange}
                />
                <Input
                  value={form.abreviatura}
                  label="Abreviatura"
                  className="input inputAreviatura"
                  variant="outlined"
                  size={"small"}
                  name="abreviatura"
                  onChange={handleChange}
                  disabled={ver || estadosCargados}
                  // onChange={handleChange}
                />
                <Input
                  value={form.descripcion}
                  label="Descripción"
                  className="input inputDesc"
                  variant="outlined"
                  size={"small"}
                  name="descripcion"
                  onChange={handleChange}
                  disabled={ver || estadosCargados}
                  // onChange={handleChange}
                />
                <Input
                  value={form.color}
                  label="Color del estado"
                  classnamediv="inputDesc"
                  className="input inputColorEstado"
                  variant="outlined"
                  size={"small"}
                  name="color"
                  onChange={handleChange}
                  disabled={ver || estadosCargados}
                  // onChange={handleChange}
                />
                {/* <Input
                  value={form.color}
                  label="Color del estado"
                  classnamediv="inputDesc"
                  className="input inputColorEstado"
                  variant="outlined"
                  size={"small"}
                  name="color"
                  onChange={handleChange}
                  disabled={ver || estadosCargados}
                  // onChange={handleChange}
                /> */}
                <Input
                  isClearable
                  reactSelect
                  options={emailOptions}
                  value={selectedOption}
                  onChange={(e) => {
                    handleChange(e, "codigo_template_email");
                  }}
                  isDisabled={ver && true}
                  placeholder="templates"
                />
              </div>

              <div className="divColumnaDos">
                <FormControlLabel
                  control={
                    <Switch
                      name="habilitado"
                      checked={form.habilitado}
                      disabled={ver}
                      onChange={handleChange}
                    />
                  }
                  label="habilitado"
                  className="switch"
                />

                <DropZoneTest
                  setImagen={setImagen_efm}
                  imgDefault={form.imagen}
                  ver={ver}
                />
              </div>
            </div>
            <div className="divTableEstadosFinales">
              <div className="divHeadTable">
                <p className="label">Mensajes predefinidos</p>
                <CustomButton
                  disabled={estadosFinalesId === "" && !editar}
                  label="Nuevo mensaje predefinido"
                  variant="contained"
                  className="buttonHeaderTable"
                  style={{ display: "flex", gap: "10px" }}
                  onClick={() => {
                    setModalStatus(true);
                    setEstadosFinalesId(
                      estadosFinalesId ? estadosFinalesId : props.datos.datos.id
                    );
                    setDatosModal({
                      datos: {
                        estadosFinales: estadosFinalesId
                          ? estadosFinalesId
                          : props.datos.datos.id,
                      },
                    });
                  }}
                  startIcon={<RenderImg img={More} />}
                />
              </div>
              {estadosFinalesId === "" && ver ? (
                <Tables
                  typeTable={"prop"}
                  columns={columns}
                  rows={mensajesPredefinidos}
                  cRows={20}
                  totalRows={itemsCount}
                  actualpage={paginaActual}
                  pagination={true}
                  page={(e) => setPaginaActual(e + 1)}
                  pages={pageCount}
                  // valueBuscador={valueBuscador}
                  buttons={buttonsDefaultver}
                  onClickButtonTable={(value, valuesRow) => {
                    if (estadosFinalesId === "") {
                      valuesRow.estadosFinales = props.datos.datos.id;
                    } else {
                      valuesRow.estadosFinales = estadosFinalesId;
                    }
                    handleClickButonTable(value, valuesRow);
                  }}
                />
              ) : (
                <Tables
                  typeTable={"prop"}
                  columns={columns}
                  rows={mensajesPredefinidos}
                  cRows={20}
                  pagination={true}
                  totalRows={itemsCount}
                  actualpage={paginaActual}
                  page={(e) => setPaginaActual(e + 1)}
                  pages={pageCount}
                  // valueBuscador={valueBuscador}
                  buttons={buttonsDefault}
                  onClickButtonTable={(value, valuesRow) => {
                    if (estadosFinalesId === "") {
                      valuesRow.estadosFinales = props.datos.datos.id;
                      valuesRow.estadoSeleccionado = estadoSeleccionado;
                      //alert("2:::"+ JSON.stringify(estadoSeleccionado));
                    } else {
                      // alert("2: "+ JSON.stringify(estadosFinalesId));
                      valuesRow.estadosFinales = estadosFinalesId;
                      valuesRow.estadoSeleccionado = estadoSeleccionado;
                    }
                    handleClickButonTable(value, valuesRow);
                  }}
                />
              )}
              <Modal2
                type="eliminar"
                isOpen={statusModal2}
                onClose={() => setModalStatus2(false)}
                onClickSuccess={() => {
                  setModalStatus2(false);
                  handleDeleteMensajes(mensajesPredefinidosId);
                }}
              ></Modal2>

              <Modal
                datos={datosModal}
                isOpen={statusModal}
                onClose={() => {
                  setModalStatus(false);
                  fetchMensajesPaginated(
                    paginaActual,
                    "",
                    estadosFinalesId,
                    "otro"
                  );
                }}
                type={"itemEstadosFinales"}
                // iconX={valueTicket === "Ver"}
              />
            </div>

            <div className="divButtonsItem">{renderButtons()}</div>
          </form>
        </div>
      </div>
    </StyledEstadosFinalesTables>
  );
};

export default EstadosFinalesModal;
