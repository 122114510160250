import React, { useState, useEffect } from 'react'
import { Styled, StyledTypography } from './EstadosOrdenesDeTrabajoStyled.js';
import { Search,  } from '@mui/icons-material';
import { Box, Chip,  } from '@mui/material';
import { RenderImgStyled } from '../../../Task/Items/SuperAdminStyled.js';
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable.js';
import {
  getEstadoOrdenesTrabajo,
  getEstadoOrdenesTrabajoPaginated,
  deleteEstadoOrdenesTrabajo,
  getEstadoOrdenesTrabajoSearch
} from '../../../../api/Configuracion.js';
import More from "../../../../assets/Icons/Buttons/More.svg";
import CustomButton from "../../../../components/Button/CustomButton.tsx"
import Input from "../../../../components/Input/Input.jsx";
import Tables from "../../../../components/Tables/Tables.jsx";
import Modal from '../../../../components/Modal/Modal.jsx';
import Modal2 from '../../../../components/Modal/Modal.jsx';
import Toast from '../../../../components/Toast/Toast.jsx';

const columnsDefaultCfg = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "accion", label: "Accion" },
  { id: "orden", label: "Orden" },
  { id: "final", label: "¿Final?" },
  { id: "cancelable", label: "¿Cancelable?" },
  { id: "cancelar", label: "¿Cancelar?" },
  { id: "estadoSiguiente", label: "Estado siguiente" },
  { id: "codigoTemplateEmail", label: "Codigo Template Email" },
  { id: "buttons" },
]

const EstadosOrdenesDeTrabajo = () => {
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [estadoOtrabajo, setEstadOtrabajo] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [key, setKey] = useState(1);
  const [estadoOtrabajoId, setEstadoOtrabajoId] = useState("");

  const handleClickButtonTable = (value, valueRow) => {
    const descripcionLabel = valueRow?.descripcion?.props?.label ?? "";
    const estadoSiguienteLabel = valueRow?.estadoSiguiente?.props?.label ?? "";

    if (value !== "Eliminar") {
      setDatosModal({
        button: value.toLowerCase(),
        datos: {
          ...valueRow,
          ...(value !== "Crear" && {
            descripcion: descripcionLabel,
            estadoSiguiente: estadoSiguienteLabel,
          }),
        },
      });
      setModalStatus(true);
    } else {
      setDatosModal("");
      setEstadoOtrabajoId(valueRow.id)
      setModalStatus2(true);
    }
    if (value === "Crear") {
      setDatosModal("");
      setModalStatus(true);
    }
  }



  useEffect(() => {
    getEstadoOrdenesTrabajoPaginated(paginaActual, orderBy, searchTerm)
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setEstadOtrabajo(
          res.data.items.map((res) => {
            return {
              id: res?.id ?? "",
              codigo: res?.codigo_ef_ot ?? "",
              descripcion: <Chip
                label={res?.desc_ef_ot || "N/A"}
                color={res?.color_ef_ot ? "default" : undefined}
                sx={{ backgroundColor: res?.color_ef_ot ?? "", color: "white" }}
              />,
              accion: res?.accion_ef_ot ?? "",
              orden: res?.orden_ef_ot ?? "",
              final: res?.final_sn_ef_ot ?? "",
              cancelable: res?.cancelable_sn_ef_ot ?? "",
              cancelar: res?.cancelar_sn_ef_ot ?? "",
              estadoSiguiente: <Chip
                label={res?.estado_siguiente?.desc_ef_ot || "N/A"}
                color={res?.estado_siguiente.color_ef_ot ? "default" : undefined}
                sx={{ backgroundColor: res?.estado_siguiente.color_ef_ot ?? "", color: "white" }}
              />,
              codigoTemplateEmail: res?.codigo_template_email_ef_ot?.description ?? "",
              templateEmailId: res?.codigo_template_email_ef_ot?.id ?? 0,
              estadoSiguienteId: res.estado_siguiente.id ?? 0,
              color: res?.color_ef_ot ?? "",
            };
          })
        );
        // console.log(res);
      })
      .catch((err) => {
        console.error("Error al obtener estados ordenes de trabajo:", err);
      });
  }, [paginaActual, orderBy]);
  //Buscador
  useEffect(() => {
    if (searchTerm === "") {
      getEstadoOrdenesTrabajoPaginated(paginaActual, orderBy, searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setEstadOtrabajo(
            res.data.items.map((res) => {
              return {
                id: res?.id ?? "",
                codigo: res?.codigo_ef_ot ?? "",
                descripcion: <Chip
                  label={res?.desc_ef_ot || "N/A"}
                  color={res?.color_ef_ot ? "default" : undefined}
                  sx={{ backgroundColor: res?.color_ef_ot ?? "", color: "white" }}
                />,
                accion: res?.accion_ef_ot ?? "",
                orden: res?.orden_ef_ot ?? "",
                final: res?.final_sn_ef_ot ?? "",
                cancelable: res?.cancelable_sn_ef_ot ?? "",
                cancelar: res?.cancelar_sn_ef_ot ?? "",
                estadoSiguiente: <Chip
                  label={res?.estado_siguiente?.desc_ef_ot || "N/A"}
                  color={res?.estado_siguiente.color_ef_ot ? "default" : undefined}
                  sx={{ backgroundColor: res?.estado_siguiente.color_ef_ot ?? "", color: "white" }}
                />,
                codigoTemplateEmail: res?.codigo_template_email_ef_ot?.description ?? "",
                templateEmailId: res?.codigo_template_email_ef_ot.id ?? 0,
                estadoSiguienteId: res.estado_siguiente.id ?? 0,
                color: res?.color_ef_ot ?? "",
              };
            })
          );
        })
        .catch((err) => {
          console.error("Error al obtener causas de fallas:", err);
        });
    } else {
      getEstadoOrdenesTrabajoSearch(searchTerm)
        .then((res) => {
          if (res.data && res.data.items && res.data.items.length > 0) {
            setPageCount(1);
            setItemsCount(res.data.items.length);
            setEstadOtrabajo(
              res.data.items.map((res) => {
                return {
                  id: res.id ?? "",
                  codigo: res.codigo_ef_ot ?? "",
                  descripcion: <Chip
                    label={res?.desc_ef_ot || "N/A"}
                    color={res?.color_ef_ot ? "default" : undefined}
                    sx={{ backgroundColor: res?.color_ef_ot ?? "", color: "white" }}
                  />,
                  accion: res.accion_ef_ot ?? "",
                  orden: res.orden_ef_ot ?? "",
                  final: res.final_sn_ef_ot ?? "",
                  cancelable: res.cancelable_sn_ef_ot ?? "",
                  cancelar: res.cancelar_sn_ef_ot ?? "",
                  estadoSiguiente: <Chip
                    label={res?.estado_siguiente?.desc_ef_ot || "N/A"}
                    color={res?.estado_siguiente.color_ef_ot ? "default" : undefined}
                    sx={{ backgroundColor: res?.estado_siguiente.color_ef_ot ?? "", color: "white" }}
                  />,
                  codigoTemplateEmail: res?.codigo_template_email_ef_ot?.description ?? "",
                  templateEmailId: res.codigo_template_email_ef_ot.id ?? 0,
                  estadoSiguienteId: res.estado_siguiente.id ?? 0,
                  color: res?.color_ef_ot ?? "",
                };
              })
            );
          } else {
            setEstadOtrabajo([]);
            setPageCount(0);
            setItemsCount(0);
          }
        })
        .catch((err) => {
          console.error("Error al obtener causa de fallas:", err);
        });
    }
  }, [searchTerm]);

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };
  const handleDeleteEstadoOtrabajo = (estadosOtrabajoId) => {
    deleteEstadoOrdenesTrabajo(estadosOtrabajoId)
      .then(() => {
        setEstadoOtrabajoId("");
        let paginaActualTemp = paginaActual;
        if (estadoOtrabajo.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getEstadoOrdenesTrabajoPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm
        );
      })
      .then((res) => {
        setMsjToast("Estado de la orden de trabajo eliminado con éxito");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setEstadOtrabajo(
            res.data.items.map((res) => {
              return {
                id: res.id ?? "",
                codigo: res.codigo_ef_ot ?? "",
                descripcion: <Chip
                  label={res?.desc_ef_ot || "N/A"}
                  color={res?.color_ef_ot ? "default" : undefined}
                  sx={{ backgroundColor: res?.color_ef_ot ?? "", color: "white" }}
                />,
                accion: res.accion_ef_ot ?? "",
                orden: res.orden_ef_ot ?? "",
                final: res.final_sn_ef_ot ?? "",
                cancelable: res.cancelable_sn_ef_ot ?? "",
                cancelar: res.cancelar_sn_ef_ot ?? "",
                estadoSiguiente: <Chip
                  label={res?.estado_siguiente?.desc_ef_ot || "N/A"}
                  color={res?.estado_siguiente.color_ef_ot ? "default" : undefined}
                  sx={{ backgroundColor: res?.estado_siguiente.color_ef_ot ?? "", color: "white" }}
                />,
                codigoTemplateEmail: res?.codigo_template_email_ef_ot?.description ?? "",
                templateEmailId: res.codigo_template_email_ef_ot.id ?? 0,
                estadoSiguienteId: res.estado_siguiente.id ?? 0,
                color: res?.color_ef_ot ?? "",
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setMsjToast("Error al eliminar el estado de trabajo.");
        setError(true);
      });
  };
  return (
    <Styled>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"estadoOrdenesDeTrabajo"}
        onClose={({ msj, status }) => {
          setModalStatus(false);
          setSuccess(false);
          setError(false);
          if (status === "success") {

            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {

            setError(true);
            setMsjToast(msj);
          }
          getEstadoOrdenesTrabajo(paginaActual, orderBy, searchTerm).then((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            setEstadOtrabajo(res.data.items.map((res) => {
              return ({
                id: res?.id ?? "",
                codigo: res?.codigo_ef_ot ?? "",
                descripcion: <Chip
                  label={res?.desc_ef_ot || "N/A"}
                  color={res?.color_ef_ot ? "default" : undefined}
                  sx={{ backgroundColor: res?.color_ef_ot ?? "", color: "white" }}
                />,
                accion: res?.accion_ef_ot ?? "",
                orden: res?.orden_ef_ot ?? "",
                final: res?.final_sn_ef_ot ?? "",
                cancelable: res?.cancelable_sn_ef_ot ?? "",
                cancelar: res?.cancelar_sn_ef_ot ?? "",
                estadoSiguiente: <Chip
                  label={res?.estado_siguiente?.desc_ef_ot || "N/A"}
                  color={res?.estado_siguiente.color_ef_ot ? "default" : undefined}
                  sx={{ backgroundColor: res?.estado_siguiente.color_ef_ot ?? "", color: "white" }}
                />,
                codigoTemplateEmail: res?.codigo_template_email_ef_ot?.description ?? "",
                templateEmailId: res?.codigo_template_email_ef_ot?.id ?? 0,
                estadoSiguienteId: res.estado_siguiente.id ?? 0,
                color: res?.color_ef_ot ?? "",
              });
            }));
          })

        }}
      />
      <StyledTypography>Estados Ordenes de Trabajo</StyledTypography>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}  
            size={"small"}
            onChange={(e) => setSearchTerm(e.target.value)}
            search
            value={searchTerm} 
            isClearable={()=>{setSearchTerm("")}} 
            className="inputComponent"
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nuevo estado de orden de trabajo"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => { handleClickButtonTable("crear") }}
        />
      </Box>
      <Tables
        typeTable={"prop"}
        columns={columnsDefaultCfg}
        rows={estadoOtrabajo}
        cRows={10}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        tableColor={false}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButtonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteEstadoOtrabajo(estadoOtrabajoId);
        }}
      />
    </Styled>
  )
}

export default EstadosOrdenesDeTrabajo