import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  deleteAnalisis,
  getAnalisisPaginated,
  getAnalisisSearch,
} from "../../../../api/Configuracion";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "unidad", label: "Unidad" },
  { id: "metodo", label: "Método" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const Analisis = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [analisiss, setAnalisiss] = useState([]);
  const [analisisId, setAnalisisId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [key, setKey] = useState(1);

  useEffect(() => {
    getAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      // alert(JSON.stringify(res.data))
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setAnalisiss(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_ana,
            descripcion: res.desc_ana,
            habilitado: res.status,
            unidad: res.unidad_id.description,
            metodo: res.metodo_id.description,
            tipo: res.tipo_id.description,
            tipoid: res.tipo_id.id,
            unidadid: res.unidad_id.id,
            metodoid: res.metodo_id.id,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    getAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      // alert(JSON.stringify(res.data))
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setAnalisiss(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_ana,
            descripcion: res.desc_ana,
            habilitado: res.status,
            unidad: res.unidad_id.description,
            metodo: res.metodo_id.description,
            tipo: res.tipo_id.description,
            tipoid: res.tipo_id.id,
            unidadid: res.unidad_id.id,
            metodoid: res.metodo_id.id,
          };
        })
      );
    });
  }, [paginaActual, orderBy]);

  useEffect(() => {
    if (searchTerm === "") {
      getAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
        // alert(JSON.stringify(res.data))
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setAnalisiss(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_ana,
              descripcion: res.desc_ana,
              habilitado: res.status,
              unidad: res.unidad_id.description,
              metodo: res.metodo_id.description,
              tipo: res.tipo_id.description,
              tipoid: res.tipo_id.id,
              unidadid: res.unidad_id.id,
              metodoid: res.metodo_id.id,
            };
          })
        );
      });
    } else {
      getAnalisisSearch("", searchTerm).then((res) => {
        if (res.data && res.data.items && res.data.items.length > 0) {
        setPaginaActual(1);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setAnalisiss(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_ana,
              descripcion: res.desc_ana,
              habilitado: res.status,
              unidad: res.unidad_id.description,
              metodo: res.metodo_id.description,
              tipo: res.tipo_id.description,
              tipoid: res.tipo_id.id,
              unidadid: res.unidad_id.id,
              metodoid: res.metodo_id.id,
            };
          })
        );
        }else{
          setAnalisiss([]);
          setPageCount(0);
          setItemsCount(0);
        }  
      });
    }
  }, [searchTerm]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      // handleDeleteAnalisis(valueRow.id)
      setAnalisisId(valueRow.id);
      setModalStatus2(true);
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDeleteAnalisis = (id) => {
    deleteAnalisis(id)
      .then(() => {
        setAnalisisId("");
        // setKey(key + 1);
        setMsjToast("Análisis eliminado con exito");
        let paginaActualTemp = paginaActual;
        if (analisiss.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getAnalisisPaginated(paginaActualTemp, orderBy, searchTerm);
      })
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Análisis eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setAnalisiss(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_ana,
                descripcion: res.desc_ana,
                habilitado: res.status,
                unidad: res.unidad_id.description,
                metodo: res.metodo_id.description,
                tipo: res.tipo_id.description,
                tipoid: res.tipo_id.id,
                unidadid: res.unidad_id.id,
                metodoid: res.metodo_id.id,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        // setKey(key + 1);
        setMsjToast("Error al eliminar Análisis");
        setError(true);
      });
  };
  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success && (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        )}

        {error && (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        )}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key+1)
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
          getAnalisisPaginated(paginaActual, orderBy, searchTerm).then(
            (res) => {
              setPageCount(res.data.totalPages);
              setItemsCount(res.data.totalItems);
              setAnalisiss(
                res.data.items.map((res) => {
                  return {
                    id: res.id,
                    codigo: res.codigo_ana,
                    descripcion: res.desc_ana,
                    habilitado: res.status,
                    unidad: res.unidad_id.description,
                    metodo: res.metodo_id.description,
                    tipo: res.tipo_id.description,
                    tipoid: res.tipo_id.id,
                    unidadid: res.unidad_id.id,
                    metodoid: res.metodo_id.id,
                  };
                })
              );
            }
          );
        }}
        type={"analisis"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Análisis"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo análisis"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={analisiss}
        cRows={analisiss.length} 
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteAnalisis(analisisId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Analisis;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;