import React, { useState, useEffect, useReducer } from 'react'
import { StyledModalcomponent } from '../../../Styled.jsx';
import { MenuItem } from '@mui/material';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {updateProtocolos,createProtocolos,getProtocolosPaginated, createAnalisis, getAnalisis, getUnidades, updateAnalisis, getMetodos, getUnidadesPaginated, getProtocolosPaginated2 } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

const ItemConjuntoAnalisis = (props) => {
  const propsItemConjuntoAnalisis = (datosConjuntoAnalisis) => {
    console.log(datosConjuntoAnalisis?.datos)
    const ver = datosConjuntoAnalisis?.button === "ver" ?? false;
    const editar = datosConjuntoAnalisis?.button === "editar" ?? false;
    const datos = datosConjuntoAnalisis?.datos ?? [];
    return { ver, editar, datos }
  }

  const { ver, editar, datos } = propsItemConjuntoAnalisis(props.datos);
  const [protocolos, setProtocolos] = useState([]);
  const [analisis, setAnalisis] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [formCompleto, setFormCompleto] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    orden: datos.orden ?? '',
    analisis: datos.descripcion ?? '',
    conjuntoAnalisis: datos.conjuntoAnalisis ?? '',
    habilitado: datos.habilitado === "ACTIVE" ||  datos.habilitado === "SI" ? true : false
  });

  

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['orden', 'analisis', 'conjuntoAnalisis', 'habilitado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  useEffect (() => {
    getAnalisis().then(res => {
      setAnalisis(res.data.items.map(res => ({ value: res.id, label: res.desc_ana })))
  
    });
    const fetchAnalisis = async () => {
      const res = await getAnalisis();
      const unidades = res?.data?.items.map(item => ({
        value: item.id,
        label: item.desc_ana,
      }));
      setAnalisis(unidades);
      if (datos) {
        const matchOption = unidades.find(option => option.label === datos.analisis);
        if (matchOption ) {
          setSelectedOption(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }
    };
    fetchAnalisis();
   
     
    

  }, []);

  useEffect(() => {
    if(selectedOption){
    setForm({ type: 'SET_FIELD', field: "analisis", value: selectedOption.value });}
  }, [selectedOption]);


  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };


  const handleSubmit = (e) => {
  //alert("Submit" + JSON.stringify(form));
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {      
      "id": form.id,
      "codigo_pro": "EFM001",
      "desc_pro": "No tiene descripcion",
      "orden_pro": form.orden,
      "habilitado_pro": "1",
      "conjunto_id": form.conjuntoAnalisis,
      "analisis_id": form.analisis,
      "created_by": "admin",
      "created_date": new Date(),
      "last_modified_by": "admin",
      "status": form.habilitado?"SI":"NO",
      "eliminado": false,
      "tenantId": 1,
    
  }

  if(editar){
    updateProtocolos(formattedData).then(res => {
      setSuccess(true);        
      setIsLoading(false);
      setMsjToast("Protocolo modificado con exito");
      setTimeout(() => {props.onClick()}, 1500);
    });
  }else{ 
  createProtocolos(formattedData).then(res => {
    setSuccess(true);
    setIsLoading(false);

      setMsjToast("Protocolo creado con exito");
      setTimeout(() => {props.onClick()}, 1500);
  });
}

} 

  const titulo = () => {
    const titulo = ver ? "Ver análisis de conjunto" : editar ? "Editar análisis de conjunto" : "Crear o editar análisis de conjunto";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
           disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponent style={{ width: "60%" }}>
         <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    
    <div classname="toast" >
   {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
   {error? <Toast type={"error"} title={"Error al " + (editar ? "modificar" : "crear") + "la unidad ?"} close={() => setError(false)}/> : null}
   </div> 

      <div className="itemConjuntoAnalisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>

        <div className="divInputsItem" style={{width: "100%"}}>
        <form className="formulario" onSubmit={handleSubmit} >
            <div className="divPrimerInput">
              <Input
                disabled
                value={form.conjuntoAnalisis}
                label="Conjunto de análisis"
                className="inputCAnalisis"
                variant="outlined"
                size={"small"}
                name="conjuntoAnalisis"
                onChange={handleChange} />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>
            
            <Input
              reactSelect
              options={analisis}
              value={selectedOption}
              onChange={(e) => { handleChange(e, "analisis") }}
              isDisabled={ver && true}
              placeholder="Análisis"
            />
          
            <Input
              value={form.orden}
              label="Orden"
              className="inputOrden input"
              variant="outlined"
              size={"small"}
              name="orden"
              onChange={handleChange}
              disabled={ver && true}
            />
             <div className="divButtonsItem">
            {renderButtons()}
          </div>
          </form>

         

        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default ItemConjuntoAnalisis