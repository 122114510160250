
import React, { useState, useEffect, useReducer } from 'react'
import { StyledModalcomponent } from '../../../Styled.jsx';
import { MenuItem } from '@mui/material';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {updateMensagesPredefinidos,createMensagesPredefinidos, getMensagesPredefinidosbyestado, getEstasdosFinalesbyId   } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

const ItemEstadosFinalesModal = (props) => {
  const propsItemConjuntoAnalisis = (datosConjuntoAnalisis) => {
    console.log(datosConjuntoAnalisis?.datos)
    const ver = datosConjuntoAnalisis?.button === "ver" ?? false;
    const editar = datosConjuntoAnalisis?.button === "editar" ?? false;
    const datos = datosConjuntoAnalisis?.datos ?? [];
    return { ver, editar, datos }
  }

  const { ver, editar, datos } = propsItemConjuntoAnalisis(props.datos);
  const [protocolos, setProtocolos] = useState([]);
  const [estadoFInalId, setEstadoFInalId] = useState(1);
  const [analisis, setAnalisis] = useState([]);  
  const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [msjToast, setMsjToast] = useState('');
  const [formCompleto, setFormCompleto] = useState(false);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState();
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    estado: datos.estadoSeleccionado ?? '',//aca tebngo q traer el id del estado final
    nombre: datos.nombre ?? '',
    sugerencia: datos.sugerencia ?? '',
    diagnostico: datos.diagnostico ?? '',
    habilitado: datos.habilitado === "SI" || datos.habilitado === "ACTIVE"  ? true : false
  });

  

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['nombre', 'sugerencia', 'diagnostico'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);

  }, [form]);

  useEffect (() => {    
    
    if(!editar && !ver){      
      getDatosEstadoFinal(datos.estadosFinales);
    }else{
      getDatosEstadoFinal(form.estado.id);
    }
   // 
   
   
  }, []);

  const getDatosEstadoFinal = (id) =>{
    
    getEstasdosFinalesbyId(id).then((res)=>{
      setEstadoSeleccionado(res.data);
    })

}

  const handleChange = (e) => {
    if (ver) {
      return
    }
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } 
   
   
   
  };

  const handleSubmit = (e) => { 
    //setIsLoading(true);
let estadoId;
if(!ver && !editar){
  estadoId = datos.estadosFinales;
}else{
  estadoId = datos.estadosFinales;
  //esta duplicad par aun control de error, despues se unificara
}
    e.preventDefault();
    let formattedData = {      
        "id": form.id,          
        "nombre_m_pre": form.nombre,
        "sugerencia_m_pre": form.sugerencia,
        "diagnostico_m_pre": form.diagnostico,
        "estado_m_pre": "dasfdasd",
        "estado_id": estadoId,
        "eliminado": false,
        "tenantId":1
      
    
  }

  if(editar){    
    updateMensagesPredefinidos(formattedData).then(res => {
      setSuccess(true);
      setIsLoading(false);
      setMsjToast("mensaje modificado con exito");
      setTimeout(() => {props.onClick()}, 1500);
    });
  }else{    
  createMensagesPredefinidos(formattedData).then(res => {
    setSuccess(true);
    setIsLoading(false);
      setMsjToast("mensaje creado con exito");
      setTimeout(() => {props.onClick()}, 1500);
  });
}

} 

  const titulo = () => {
    const titulo = ver ? "Ver mensajes predefinidos" : editar ? "Editar mensajes predefinidos" : "Crear o editar mensajes predefinidos";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            onClick={handleSubmit}
           disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponent style={{ width: "60%" }}>
    <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    
    <div classname="toast" >   {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
   {error? <Toast type={"error"} title={"Error al " + (editar ? "modificar" : "crear") + "la unidad ?"} close={() => setError(false)}/> : null}
   </div> 

      <div className="itemConjuntoAnalisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>

        <div className="divInputsItem" style={{width: "100%"}}>
        <form className="formulario" onSubmit={handleSubmit} >
       
            <div className="divPrimerInput">
              <Input
                
                value={estadoSeleccionado?estadoSeleccionado.desc_efm:form.estado}
                label="estado"
                className="inputCAnalisis"
                variant="outlined"
                size={"small"}
                name="estado"
                disabled={true}
                onChange={handleChange} />
             
            </div>
            <Input
              value={form.nombre}
              label="Nombre"
              placeholder="Nombre"              
              className="inputAnalisis input"
              variant="outlined"
              size={"small"}
              disabled={ver}

              name="nombre"
              onChange={handleChange}             
            >
            </Input>
            <Input
              value={form.sugerencia}
              label="Sugerencia"
              placeholder="Sugerencia"              
              className="inputAnalisis input"
              variant="outlined"
              size={"small"}
              name="sugerencia"
              onChange={handleChange}
              disabled={ver}

             
            >
                      </Input>
            <Input
              value={form.diagnostico}
              label="Diagnóstico"
              className="inputAnalisis input"
              variant="outlined"
              size={"small"}
              name="diagnostico"
              onChange={handleChange}
              disabled={ver}
            />
             <div className="divButtonsItem">
            {renderButtons()}
          </div>
          </form>

         

        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default ItemEstadosFinalesModal