import axios from "./axios";

export const getOrdenesTrabajo = (page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getOrdenesTrabajoByCliente = (idCli, page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo/cliente/" +
      idCli +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const deleteOrdenTrabajo = (id) =>
  axios.delete(`/deleteOrdenTrabajo/${id}`);

export const getOrdenesTrabajoByPlanta = (idPla, page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo/planta/" +
      idPla +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getOrdenesTrabajoByEstado = (idEst, page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo/estado/" +
      idEst +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

//https://be-dev.limsoil.com/allOrdenTrabajo/estado/:idEstado
//https://be-dev.limsoil.com/allOrdenTrabajo/c/:idPlanta
//https://be-dev.limsoil.com/allOrdenTrabajo/cliente/:idCliente

export const getOrdenesTrabajoAllFilters = (
  idCli,
  idPla,
  idEst,
  page,
  sort,
  search
) =>
  axios.get(
    "/allOrdenTrabajo?idCliente=" +
      idCli +
      "&idPlanta=" +
      idPla +
      "&idEstadoOT=" +
      idEst +
      "&page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getOrdenesTrabajoAllFiltersTest = (
  idCli,
  idPla,
  idEst,
  page,
  sort,
  search
) =>
  axios.get(
    "/allOrdenTrabajo?idCliente=" +
      idCli +
      "&idPlanta=" +
      idPla +
      "&idEstadoOT=" +
      idEst +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

//https://be-dev.limsoil.com/allOrdenTrabajo?idCliente=15&idPlanta=47&idEstadoOT=1&page=1&pageSize=10

export const getEstadoOrdenesOptions = () =>
  axios.get("/allEstadoOrdenesTrabajoOptions");

export const getEstadosOrdenesTrabajo = () =>
  axios.get("/allEstadoOrdenesTrabajoWithoutPagination");

export const getMuestrasPaginated = (page, sort, search) =>
  axios.get(
    "/allOrdenesTrabajo?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const createOrdenTrabajo = (orden) =>
  axios.post("/createOrdenTrabajo", orden);
export const updateOrdenTrabajo = (orden) =>
  axios.put(`/updateOrdenTrabajo`, orden);

export const avanzarOrdenTrabajo = (id) =>
  axios.put(`/avanzarEstado/${id}`);
