import {
  Box,
  styled,
  Table,
  Pagination,
  TablePagination,
  TableContainer,
  TableCell,
  Typography,
} from "@mui/material";
import { colors, fonts } from "../../assets/styles/theme.ts";
import RenderImg from "../RenderImg/RenderImg.tsx";
export {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
export const Styled = styled(Box)`
  height: 100%;
`;
export const TableContainerStyled = styled(TableContainer)`
  && {
    height: 100%;
  }
`;
export const TableStyled = styled(Table)`
  && {
    .headTable {
      background-color: ${colors.blueLight};
    }
  }
`;
export const LabelHeadColumn = styled(Typography)`
  && {
    font-family: ${fonts.primary};
    color: ${colors.blueColumnTable};
    font-weight: 600;
    font-size: 12px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
export const RenderImgStyled = styled(RenderImg)`
  cursor: pointer;
  height: 17px;
  padding:0px 10px;
`;
export const TableCellStyled = styled(TableCell)`
.logocelda{
  height: 30px!important;
}
img .logocelda {
  height: 30px;
}
  && {
    margin: 0px;
    padding: 10px 15px;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.blueColumnTable};
  }

`;
export const DivButtonsTable = styled(Box)`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  width: 95%;
  .buttonVer,.buttonEditar,.buttonEliminar {
        background: #dbe5e8;
        border-radius: 10px;
        :hover{color:white;font-weight:bold;}
  }
  .buttonVer:hover {
    background-color: #66bbd3;
  }
  .buttonEditar:hover {
    background-color: #d2e895;
  }
  .buttonEliminar:hover {
    background-color: #ff5454;
  }
   .buttonClientes{
      background: #AFCCD5;
      border-radius: 10px;
      width: 35px;
      height: 36px;
      :hover{
        font-weight:bold;
        background:#2572E6
      }
  }
  .buttonMuestras{
      background: #dbe5e8;
      border-radius: 10px;
      width: 35px;
      height: 36px;
      :hover{
        font-weight:bold;
        background:#AFCCD5
      }
  }
  .buttonImprimir{
      background: #dbe5e8;
      border-radius: 10px;
      width: 35px;
      height: 36px;
      :hover{
        background:#C160E3;
        font-weight:bold;
      }
  }
  .buttonDiagnostico{
      background: #dbe5e8;
      border-radius: 10px;
      width: 35px;
      height: 36px;
      :hover{
        background:#4ADA06;
        font-weight:bold;
      }
  }
`;
export const PaginationStyled = styled(Pagination)`
  && {
    display: flex;
    justify-content: center;
  }
`;
export const TablePaginationStyled = styled(TablePagination)`
  && {
    display: flex;
    justify-content: center;
  }
`;

// const Styled = styled.div`
// //HEADER

// //configuracion
// .divHeaderCfg{
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   padding:20px 20px 20px 0px;
//   .divInput{
//     width:40%;
//   }
// }

// //clientes
// .divHeaderClientes {
//   padding:20px 20px 20px 0px;
//   .setFiltro{padding: 5px 0px 0px 15px;}
//   //client//plantas//equipos
//   .divClientesItem,.divPlantasItem,.divEquiposItem,.divPuntosdeTomaItem,.divIntervencionesItem{
//     display:flex;
//     justify-content: space-between;
//     .divInputs,.divButtonsHeader{
//       display:flex;
//       gap:20px;
//     }
//     .divInputClientes,.divInputPlantas,.divInputEquipos,.divInputPuntosDeToma,
//     .divInputIntervencion{
//       width:400px;
//     }
//     .keyUno{
//       svg{transform:rotate(180deg);}
//     }
//   }
// }
// //Muestras
// .divHeaderMuestras {
//   padding:20px 20px 20px 0px;
//   .setFiltro{
//     padding: 5px 0px 0px 15px;
//     .divCheckBoxButtons{
//       display:flex;
//       padding:10px 0px;
//       justify-content: space-between;
//       .divCheckBox{display:flex}
//       .divButtonsCheck{display:flex;gap:10px;}
//     }
//   }

//   .divOrdenesDeTrabajoItem,.divMuestrasItem{
//     display:flex;
//     justify-content: space-between;
//     .divInputs,.divButtonsHeader{
//       display:flex;
//       gap:20px;
//     }
//     .divInputOrdenesDeTrabajo,.divInputClientes,.divInputPlanta,.divInputOt,.divInputEquipo{
//       width:270px;
//     }
//     .divInputNumero{
//       width:200px;
//     }
//     .divInputEstado{
//       width: 300px;
//       padding: 0px 20px;
//     }
//   }
// }

// //Tabla
//   .table {
//     height: 70vh;
//   }
//   .headerTable,
//   .tableCell,
//   .headTable {
//     .labelHead {
//       font-family: Inter;
//       font-weight: 600;
//       color: #102d3d;
//       margin: 0px;
//     }
//   }
//   .headTable {
//     background-color: #afccd5;
//   }
//   .tableCell {
//     margin: 0px;
//     padding: 10px 15px;
//     font-family: Inter;
//     font-weight: 600;
//     color: #102d3d;
//   }
//   .divValueHabilitado,.divValueEstado {
//     justify-content: space-between;
//     width: 100%;
//     align-items: center;
//     .value{
//       margin:0px;
//     }
//   }
//   .divButtons{
//     gap: 5px;
//     justify-content: flex-end;
//     //Configuracion buttons
//     .buttonVer,.buttonEditar,.buttonEliminar {
//         background: #dbe5e8;
//         border-radius: 10px;
//         :hover{color:white;font-weight:bold;}
//     }
//     //Clientes buttons
//     .buttonClientes{
//         background: #AFCCD5;
//         border-radius: 10px;
//         :hover{color:white;font-weight:bold;}
//     }
//     svg {
//         color: #163c5b;
//         max-width: 80%;
//     }
//     }


//     //Clientes
//     .divButtons > .buttonClientes:hover {
//     background-color: #2572E6;
//     }
//     .divImagenLogo{
//       max-width: 100%;
//       height: auto;
//       .img{
//         max-width: 100%;
//         height: auto;
//         display: block;
//       }
//     }

//   .pagination {
//     justify-content: center;
//   }

// `;
