import React, { useState, useEffect } from 'react';
import Tables from '../../../../components/Tables/Tables';
import HeaderTable from '../../../../components/HeaderTable/HeaderTable';
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';
import Toast from '../../../../components/Toast/Toast';

import Modal2 from "../../../../components/Modal/Modal";
import { getConjuntosAnalisis, deleteConjuntosAnalisis, getTiposConjuntosAnalisis, getConjuntosAnalisisPaginated } from '../../../../api/Configuracion';

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
]
const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    habilitado: "Habilitado",
    tipo: "tipo"
  }
]
const ConjuntoDeAnalisis = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [conjuntosAnalisis, setConjuntosAnalisis] = useState([]);
  const [conjuntoAnalisisId, setConjuntoAnalisisId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [tiposConjuntoAnalisis, setTiposConjuntoAnalisis] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);

  useEffect(() => {
    getConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setConjuntosAnalisis(res.data.items.map((res) => {
        return ({ tipoId: res.tipocana.id, tipo: res.tipocana.desc_tpcana, id: res.id, codigo: res.codigo_cana, descripcion: res.desc_cana, habilitado: res.status });
      }));
    })
    getTiposConjuntosAnalisis().then(res => {
      setTiposConjuntoAnalisis(res.data.items.map(res => ({ value: res.id, label: res.desc_cana })))
    })
  }, [])

  useEffect(() => {
    getConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setConjuntosAnalisis(res.data.items.map((res) => {
        return ({ tipoId: res.tipocana.id, tipo: res.tipocana.desc_tpcana, id: res.id, codigo: res.codigo_cana, descripcion: res.desc_cana, habilitado: res.status });
      }));
    })
    getTiposConjuntosAnalisis().then(res => {
      setTiposConjuntoAnalisis(res.data.items.map(res => ({ value: res.id, label: res.desc_cana })))
    })
  }, [searchTerm, orderBy, paginaActual])


  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setConjuntoAnalisisId(valueRow.id);
      setModalStatus2(true);
      //handleDelete(valueRow.id)
    }
  }
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

  const handleDelete = (unidadId) => {
    deleteConjuntosAnalisis(unidadId)
      .then(() => {
        setConjuntoAnalisisId("");
        return getConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm);
      })
      .then(res => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);

        setSuccess(true);
        setMsjToast("Conjunto de Análisis eliminada con exito");
        setTimeout(() => {
          setSuccess(false);
          setConjuntosAnalisis(res.data.items.map((res) => {
            setPageCount(res.data?.totalPages || 0);
            setItemsCount(res.data?.totalItems || 0);
            return ({ tipoId: res.tipocana.id, tipo: res.tipocana.desc_tpcana, id: res.id, codigo: res.codigo_cana, descripcion: res.desc_cana, habilitado: res.status });
          }));
        }, 1500);

      })
      .catch(err => console.error("Error al eliminar el tenant:", err));
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled>
      <div className="toast" >
        {success ? <Toast title={msjToast} close={() => setSuccess(false)} /> : null}
        {error ? <Toast type={"error"} title={msjToast} close={() => setError(false)} /> : null}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => {
          setModalStatus(false);
          getConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            setConjuntosAnalisis(res.data.items.map((res) => {
              return ({ tipoId: res.tipocana.id, tipo: res.tipocana.desc_tpcana, id: res.id, codigo: res.codigo_cana, descripcion: res.desc_cana, habilitado: res.status });
            }));
          })
        }}
        type={"conjuntoAnalisis"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Conjunto de Análisis"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo conjunto de análisis"}
        onClickButton={() => { handleClickButtonHeader() }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={conjuntosAnalisis}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => { handleOrder(value) }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(conjuntoAnalisisId);
        }}
      ></Modal2>
    </Styled>
  )
}

export default ConjuntoDeAnalisis

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    //height: 100vh;
  }
`