import React, { useState, useEffect } from 'react';
import Tables from '../../../../components/Tables/Tables';
import HeaderTable from '../../../../components/HeaderTable/HeaderTable';
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';
import Toast from '../../../../components/Toast/Toast';
import Modal2 from '../../../../components/Modal/Modal';
import { getTipoAnalisiss, getTipoAnalisisPaginated, createTipoAnalisis, deleteTipoAnalisis, getTipoAnalisisSearch } from '../../../../api/Configuracion';
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "abreviatura", label: "Abreviatura" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
]
const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    abreviatura: "abreviatura",
    habilitado: "Habilitado",
  }
]
const TipodeAnalisis = () => {
  const [statusModal, setModalStatus] = useState(false);

  const [statusModal2, setModalStatus2] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoAnalisiss, setTipoAnalisiss] = useState([]);
  const [tipoAnalisisId, setTipoAnalisisId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [key, setKey] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");

  useEffect(() => {
    getTipoAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      // alert(JSON.stringify(res.data))
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setTipoAnalisiss(res.data.items.map((res) => {

        return ({ id: res.id, codigo: res.codigo_tpana, descripcion: res.desc_tpana, abreviatura: res.abrev_tpana, habilitado: res.status });
      }));
    })
  }, [])

  useEffect(() => {
    getTipoAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      // alert(JSON.stringify(res.data))
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setTipoAnalisiss(res.data.items.map((res) => {

        return ({ id: res.id, codigo: res.codigo_tpana, descripcion: res.desc_tpana, abreviatura: res.abrev_tpana, habilitado: res.status });
      }));
    })
  }, [paginaActual, orderBy])

  useEffect(() => {
    if (searchTerm === "") {
      getTipoAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTipoAnalisiss(res.data.items.map((res) => {

          return ({ id: res.id, codigo: res.codigo_tpana, descripcion: res.desc_tpana, abreviatura: res.abrev_tpana, habilitado: res.status });
        }));
      }).catch((err) =>
        console.error("Error al obtener datos de tipo analisis:", err)
      );
    } else {
      setPaginaActual(1);
      getTipoAnalisisSearch("", searchTerm).then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTipoAnalisiss(res.data.items.map((res) => {

          return ({ id: res.id, codigo: res.codigo_tpana, descripcion: res.desc_tpana, abreviatura: res.abrev_tpana, habilitado: res.status });
        }));
      }).catch((err) =>
        console.error("Error al obtener datos de tipo analisis:", err)
      );

    }
  }, [searchTerm])
  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setTipoAnalisisId(valueRow.id);
      setModalStatus2(true);
      // handleDeleteTipoAnalisis(valueRow.id)
    }
  }
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

  const handleDeleteTipoAnalisis = (id) => {
    deleteTipoAnalisis(id)
      .then(() => {
        setTipoAnalisisId("");
        // setKey(key+1);
        setMsjToast("Tipo de de Análisis eliminado con exito");
        let paginaActualTemp = paginaActual;
        if ((tipoAnalisiss.length - 1) === 0 && paginaActual > 1) {
          paginaActualTemp = (paginaActual - 1);
          setPaginaActual(paginaActualTemp);
        }
        return getTipoAnalisisPaginated(paginaActualTemp, orderBy, searchTerm);
      })
      .then(res => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Tipo de Análisis eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setTipoAnalisiss(
            res.data.items.map((res) => {
              return ({ id: res.id, codigo: res.codigo_tpana, descripcion: res.desc_tpana, abreviatura: res.abrev_tpana, habilitado: res.status });

            })
          );
        }, 1500);
      })
      .catch((err) => {
        // setKey(key+1);
        setMsjToast("Error al eliminar El tipo de Análisis")
        setError(true)
      });
  }
  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div classname="toast" >
        {success && <Toast key={key} title={msjToast} close={() => setSuccess(false)} />}

        {error && <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setModalStatus(false);
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key+1)
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          }
          getTipoAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            setTipoAnalisiss(res.data.items.map((res) => {
              return ({ id: res.id, codigo: res.codigo_tpana, descripcion: res.desc_tpana, abreviatura: res.abrev_tpana, habilitado: res.status });
            }));
          })

        }}
        type={"tipoAnalisis"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipo de Análisis"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo tipo de análisis"}
        onClickButton={() => { handleClickButtonHeader() }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoAnalisiss}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        onClickSwitch={(value) => { handleOrder(value) }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTipoAnalisis(tipoAnalisisId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipodeAnalisis;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
   // height: 100vh;
  }
`