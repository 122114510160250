import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Styled, StyledTypography } from './CausasDeFallaStyled.js';
import { Search } from '@mui/icons-material';
import { RenderImgStyled } from '../../../Task/Items/SuperAdminStyled';
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import {
  getCausasDeFallasSearch,
  getCausasDeFallasPaginated,
  deleteCausasDeFallas,
} from '../../../../api/Configuracion.js';
import Input from "../../../../components/Input/Input";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import More from "../../../../assets/Icons/Buttons/More.svg";
import Tables from "../../../../components/Tables/Tables.jsx";
import Modal from "../../../../components/Modal/Modal.jsx";
import Modal2 from "../../../../components/Modal/Modal.jsx";
import Toast from '../../../../components/Toast/Toast.jsx';

const columnsDefaultCfg = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
]


const CausasDeFalla = () => {
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [causasDeFalla, setCausasDeFalla] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [key, setKey] = useState(1);
  const [causasDeFallasId, setCausasDeFallasId] = useState("");


  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      setModalStatus(true);
    } else {
      setDatosModal("");
      setCausasDeFallasId(valueRow.id)
      setModalStatus2(true);
    }
    if (value === "Crear") {
      setDatosModal("");
      setModalStatus(true);
    }
  }
  //Datos al modal
  useEffect(() => {
    getCausasDeFallasPaginated(paginaActual, orderBy, searchTerm)
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setCausasDeFalla(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_falla,
              descripcion: res.desc_falla,
              tipo: res.tipo_falla_id.description,
              tipoId: res.tipo_falla_id.id,
              habilitado: res.status,
            };
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.error("Error al obtener causas de fallas:", err);
      });
  }, [paginaActual, orderBy]);
  //Buscador
  useEffect(() => {
    if (searchTerm === "") {
      getCausasDeFallasPaginated(paginaActual, orderBy, searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setCausasDeFalla(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_falla,
                descripcion: res.desc_falla,
                tipo: res.tipo_falla_id.description,
                tipoId: res.tipo_falla_id.id,
                habilitado: res.status,
              };
            })
          );
        })
        .catch((err) => {
          console.error("Error al obtener causas de fallas:", err);
        });
    } else {
      getCausasDeFallasSearch(searchTerm)
        .then((res) => {
          if (res.data && res.data.items && res.data.items.length > 0) {
            setPageCount(1);
            setItemsCount(res.data.items.length);
            setCausasDeFalla(
              res.data.items.map((res) => {
                console.log(res);
                return {
                  id: res.id,
                  codigo: res.codigo_falla,
                  descripcion: res.desc_falla,
                  tipo: res.tipo_falla_id.description,
                  habilitado: res.status,
                };
              })
            );
          } else {
            setCausasDeFalla([]);
            setPageCount(0);
            setItemsCount(0);
          }
        })
        .catch((err) => {
          console.error("Error al obtener causa de fallas:", err);
        });
    }
  }, [searchTerm]);

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };
  const handleDeleteCausasDeFalla = (causasDeFallasId) => {
    deleteCausasDeFallas(causasDeFallasId)
      .then(() => {
        setCausasDeFallasId("");
        let paginaActualTemp = paginaActual;
        if (causasDeFalla.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getCausasDeFallasPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm
        );
      })
      .then((res) => {
        setMsjToast("Causa de fallas eliminado con exito.");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setCausasDeFalla(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_falla,
                descripcion: res.desc_falla,
                tipo: res.tipo_falla_id.description,
                tipoId: res.tipo_falla_id.id,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setMsjToast("Error al eliminar causa de fallas.");
        setError(true);
      });
  };
  return (
    <Styled key={key}>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status, newItem }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setMsjToast(msj);
            setSuccess(true);
            if (newItem) {
              setCausasDeFalla((prev) => [...prev, newItem]);
            }
            getCausasDeFallasPaginated(paginaActual, orderBy, searchTerm)
              .then((res) => {
                setPageCount(res.data.totalPages);
                setItemsCount(res.data.totalItems);
                setCausasDeFalla(
                  res.data.items.map((res) => ({
                    id: res.id,
                    codigo: res.codigo_falla,
                    descripcion: res.desc_falla,
                    tipo: res.tipo_falla_id.description,
                    tipoId: res.tipo_falla_id.id,
                    habilitado: res.status,
                  }))
                );
              });
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
        type={"causasDeFallas"}
      />

      <StyledTypography>Causas de Falla</StyledTypography>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            className="inputComponent"
            value={searchTerm}
            search
            isClearable={() => { setSearchTerm("") }}
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nueva causa de falla"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => { handleClickButtonTable("crear") }}
        />
      </Box>
      <Tables
        typeTable={"prop"}
        columns={columnsDefaultCfg}
        rows={causasDeFalla.length > 0 ? causasDeFalla : []}
        cRows={10}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        tableColor={false}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButtonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteCausasDeFalla(causasDeFallasId);
        }}
      />
    </Styled>
  )
}

export default CausasDeFalla