import React, { useReducer, useEffect, useState } from 'react';
import { StyledModalcomponent } from '../../../Styled';
import Input from '../../../../Input/Input';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  createEstadoOrdenesTrabajo,
  updateEstadoOrdenesTrabajo,
  getOptionsTemplateEmails,
  getTemplateEmails,
  getEstadoOrdenesTrabajoOptions,
} from '../../../../../api/Configuracion.js';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';
const EstadosOrdenesDeTrabajoModal = (props) => {
  const propsEstadosOrdenesDeTrabajo = (datosEstadosOrdenesDeTrabajo) => {
    const ver = datosEstadosOrdenesDeTrabajo?.button === "ver" ?? false;
    const editar = datosEstadosOrdenesDeTrabajo?.button === "editar" ?? false;
    const datos = datosEstadosOrdenesDeTrabajo?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsEstadosOrdenesDeTrabajo(props.datos);

  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? "",
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    accion: datos.accion ?? '',
    orden: datos.orden ?? '',
    final: datos.final ?? 'NO',
    cancelable: datos.cancelable ?? 'NO',
    cancelar: datos.cancelar ?? 'NO',
    color: datos?.color ?? '',
    codigoTemplateEmail: datos.codTemplateEmails ?? '',
    estadoSiguiente: datos.estadoSiguiente ?? '',
    templateEmailsId: datos.id ?? '',
    estadoSiguienteId: datos.estadoSiguienteId ?? '',
  });

  const [formCompleto, setFormCompleto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templateEmails, setTemplateEmails] = useState([]);
  const [estadosSiguientes, setEstadosSiguientes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionEstadosSiguientes, setSelectedOptionEstadosSiguientes] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [codTemplateEmails, setCodTemplateEmails] = useState([])
  const [key, setKey] = useState(0);



  useEffect(() => {
    const fetchAllTemplateEmails = async () => {
      const res = await getTemplateEmails();
      if (selectedOption && selectedOption.value) {
        const matchedItem = res?.data?.items.find(item => item.id === selectedOption.value);
        setCodTemplateEmails(matchedItem ? matchedItem.codigo_t_em : undefined);
      } else {
        setCodTemplateEmails(undefined);
      }
    };
    fetchAllTemplateEmails();
  }, [selectedOption]);

  useEffect(() => {
    const fetchTemplateEmailsOptions = async () => {
      const res = await getOptionsTemplateEmails();
      const templateEmailsData = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_t_em,
      })) || [];

      setTemplateEmails(templateEmailsData);

      if (datos && (editar || ver)) {
        const matchOption = templateEmailsData.find(
          (option) => option.value === datos.templateEmailId
        );
        setSelectedOption(matchOption);
      } else if (templateEmailsData.length > 0 && editar) {
        setSelectedOption();
      }
    };

    fetchTemplateEmailsOptions();
  }, [editar, ver, datos]);

  useEffect(() => {
    const fetchEstadosSiguientes = async () => {
      const res = await getEstadoOrdenesTrabajoOptions();
      const estadosSiguientesData = res?.data?.filter(item => item.id !== 1).map((item) => ({
        value: item.id,
        label: item.desc_ef_ot,
        color: item.color_ef_ot,
      })) || [];

      setEstadosSiguientes(estadosSiguientesData);
      if (datos && (editar || ver)) {
        const matchOption = estadosSiguientesData.find(
          (option) => option.value === datos.estadoSiguienteId
        );
        setSelectedOptionEstadosSiguientes(matchOption);
      } else if (estadosSiguientesData.length > 0 && editar) {
        setSelectedOptionEstadosSiguientes();
      }
    };

    fetchEstadosSiguientes();
  }, [editar, ver, datos]);

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "";
    const camposaValidar = [
      "codigo",
      "descripcion",
      "orden",
      "color",
      "codigoTemplatEmail",
      // "estadoSiguiente",
      "final",
      "cancelable",
      "cancelar"
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);
  const titulo = () => {
    const titulo = ver ? "Ver estado orden de trabajo" : editar ? "Editar estado orden de trabajo" : "Crear o editar estado orden de trabajo";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formatterForm = {
      id: datos.id ?? form.id,
      codigo_ef_ot: form.codigo ?? "",
      desc_ef_ot: form.descripcion ?? "",
      estado_siguiente: String(selectedOption?.value) ?? null,
      color_ef_ot: form.color ?? "",
      final_sn_ef_ot: form.final ?? "",
      cancelable_sn_ef_ot: form.cancelable ?? "",
      cancelar_sn_ef_ot: form.cancelar ?? "",
      codigo_template_email_ef_ot: Array.isArray(codTemplateEmails) ? "" : (codTemplateEmails ?? ""),
      orden_ef_ot: form.orden ?? "",
      accion_ef_ot: form.accion ?? "",
      siguiente_id: selectedOptionEstadosSiguientes?.value ?? null,
      eliminado: false,
      tenantId: "1",
      created_by: "admin",
      created_date: "2024-07-17T10:00:00Z",
      last_modified_by: "admin",
      last_modified_date: "2024-07-17T10:00:00Z",
      status: "ACTIVE",
    };
    if (editar) {
      updateEstadoOrdenesTrabajo(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Orden de trabajo editada con éxito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo editar la orden de trabajo",
            status: "error",
          });
        });
    } else {
      createEstadoOrdenesTrabajo(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({ msj: "Se creo la Orden de trabajo con exito", status: "success" });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({ msj: "No se Pudo Crear la Orden de trabajo", status: "error" });
        });
    }
  };
  const handleCheckboxChange = ({ checked, name }) => {
    setForm({
      type: 'SET_FIELD',
      field: name,
      value: checked ? "SI" : "NO"
    });
  };
  const handleChange = (e, nameReactSelect) => {
    if (e && e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e ? e.value : null;
      const label = e ? e.label : null;
      const name = nameReactSelect;
      if (nameReactSelect === "codigoTemplateEmail") {
        setSelectedOption(e);
      }
      if (nameReactSelect === "estadoSiguiente") {
        setSelectedOptionEstadosSiguientes(e);
      }
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };
  console.log(estadosSiguientes)
  console.log(selectedOptionEstadosSiguientes)
  return (
    <StyledModalcomponent style={{ width: "80%" }}>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <form className="formulario" onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div className="estadoOrdenesDeTrabajo">
          <div className="divLabel">
            <p className="titulo"> {titulo()}</p>
          </div>

          <div className="Ainput">
            <Input
              name="codigo"
              label="Código"
              type="text"
              placeholder="Código"
              variant="outlined"
              className="input"
              value={form.codigo}
              onChange={handleChange}
              disabled={ver && true}
            />
            <Input
              name="descripcion"
              label="Descripción"
              type="text"
              placeholder="Descripción"
              variant="outlined"
              className="input descripcion"
              value={form.descripcion}
              onChange={handleChange}
              disabled={ver && true}
            />
            <Input
              name="accion"
              label="Acción"
              type="text"
              placeholder="Acción"
              variant="outlined"
              className="input descripcion"
              value={form.accion}
              onChange={handleChange}
              disabled={ver && true}
            />
          </div>
          <div className="Binput">
            <Input
              name="orden"
              label="Orden"
              type="text"
              placeholder="Orden"
              variant="outlined"
              className="input"
              value={form.orden}
              onChange={handleChange}
              disabled={ver && true}
            />
            <Input
              name="color"
              label="Color"
              type="text"
              placeholder="Color"
              variant="outlined"
              className="input color"
              value={form.color}
              onChange={handleChange}
              disabled={ver && true}
            />

          </div>
          <div className="Cinput">

            <Input
              reactSelect
              options={templateEmails}
              value={selectedOption}
              onChange={(e) => {
                handleChange(e, "codigoTemplateEmail");
              }}
              isDisabled={ver && true}
              placeholder="Codigo Template Email"
              isClearable={true}
            />
            <Input
              reactSelect
              // options={estadosSiguientes.filter(
              //   (estado) => estado.label !== form.descripcion 
              // )}
              options={estadosSiguientes}
              value={selectedOptionEstadosSiguientes}
              onChange={(e) => {
                handleChange(e, "estadoSiguiente");
              }}
              isDisabled={ver && true}
              placeholder={"Estado Siguiente"}
              isClearable={true}
              activeLegendReactSelect={ver && true}
              getOptionLabel={(option) => option.label} 
              getOptionValue={(option) => option.value} 
            />
            <div className="divCheckBox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.final === "SI"}
                    onChange={(e) => handleCheckboxChange({ checked: e.target.checked, name: 'final' })}
                    name="final"
                    disabled={ver && true}
                  />
                }
                label="Final"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.cancelable === "SI"}
                    onChange={(e) => handleCheckboxChange({ checked: e.target.checked, name: 'cancelable' })}
                    name="cancelable"
                    disabled={ver && true}
                  />
                }
                label="Cancelable"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.cancelar === "SI"}
                    onChange={(e) => handleCheckboxChange({ checked: e.target.checked, name: 'cancelar' })}
                    name="cancelar"
                    disabled={ver && true}
                  />
                }
                label="Cancelar"
              />
            </div>
          </div>
        </div>
        <div className="divButtonsItem" style={{ margin: "auto" }}>
          {renderButtons()}
        </div>
      </form>
    </StyledModalcomponent>
  )
}

export default EstadosOrdenesDeTrabajoModal