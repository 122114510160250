import React, { useReducer, useState, useEffect } from 'react'
import CustomButton from '../../../../Button/CustomButton.tsx';
import Input from '../../../../Input/Input.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';
import Toast from '../../../../Toast/Toast.jsx';
import { StyledModalcomponent } from '../../../Styled';
import { FormControlLabel, Switch } from '@mui/material';
import { createTipoFallas, updateTipoFallas } from '../../../../../api/Configuracion.js';

const TiposDeFallasModal = (props) => {
  const propsTipoDeFallas = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  
  const { ver, editar, datos } = propsTipoDeFallas(props.datos);
  const [formCompleto, setFormCompleto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? "",
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    habilitado: datos.habilitado === "ACTIVE" || datos.habilitado === "SI" ? true : false
  });
  const titulo = () => {
    const titulo = ver ? "Ver tipos de falla" : editar ? "Editar tipos de falla" : "Crear o editar tipo de falla";
    return titulo
  }
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (ver) {
      return
    }
    const fieldValue = name === 'habilitado' ? checked : value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };
  useEffect(() => {
    const campoValido = (campo) => form[campo] !== "";
    const camposaValidar = [
      "codigo",
      "descripcion",
      "habilitado",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formatterForm  = {
      codigo_tpfalla: form.codigo,
      desc_tpfalla:form.descripcion,
      status: form.habilitado ? "SI" : "NO",
      created_by: "usuario_creador",
      eliminado: false,
      created_date: new Date(),
      tenantId: 1,
    }
    if (editar) {
      formatterForm.id = form.id;
      updateTipoFallas(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Tipos de falla modificado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo modificar el tipos de falla.",
            status: "error",
          });
        });
    } else {
      createTipoFallas(formatterForm)
        .then(() => {
          setIsLoading(false);
          props.onClick({
            msj: "Tipos de falla creado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear el tipos de falla.",
            status: "error",
          });
        });
    }
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponent >
        <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <form className="formulario" onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div className="tiposDeFalla">
          <div className="divLabel">
            <p className="titulo"> {titulo()}</p>
          </div>

          <div className="Ainput">
            <Input
              name="codigo"
              label="codigo"
              type="text"
              placeholder="Código"
              variant="outlined"
              className="input"
              value={form.codigo}
              onChange={handleChange}
              disabled={ver && true}
            />
            <FormControlLabel
              control={<Switch
                name="habilitado"
                disabled={ver}
                checked={form.habilitado}
                value={form.habilitado}
                onChange={handleChange}
              />}
              label="Habilitar"
            />
          </div>
          <div className="Binput" style={{ flexDirection: "column" }}>
            <Input
              name="descripcion"
              label="descripcion"
              type="text"
              placeholder="Descripción"
              variant="outlined"
              className="input descripcion"
              value={form.descripcion}
              onChange={handleChange}
              disabled={ver && true}
            />
          </div>
        </div>
        <div className="divButtonsItem" style={{ margin: "auto" }}>
          {renderButtons()}
        </div>
      </form>

    </StyledModalcomponent>
  )
}

export default TiposDeFallasModal