import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsMuestras } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  getMuestrasPaginated,
  getOrdenesTrabajo,
} from "../../../../api/Muestras";

const columns = [
  { id: "fechaProgr", label: "Fecha Progr." },
  { id: "codigo", label: "Código" },
  { id: "lote", label: "Lote" },
  { id: "denominacion", label: "Denominación" },
  { id: "cliente", label: "Cliente" },
  { id: "planta", label: "Planta" },
  { id: "estado", label: "Estado" },
  { id: "buttons" },
];

const rows = [
  {
    fechaProgr: "fecha",
    codigo: "codigo",
    lote: "lote",
    denominacion: "denominacion",
    cliente: "cliente",
    planta: "planta",
    estado: "estado",
  },
];

const buttonsHeadTable = [
  {
    label: "Nueva muestra",
    classname: "buttonHeaderTable",
  },
];

const datosInputsHeaderTable = [
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "hola",
    inputSelect: true,
    width: "250px",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "hola",
    inputSelect: true,
    width: "250px",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "chau",
    inputSelect: true,
    width: "250px",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
  {
    classnamediv: "divInputSelect",
    className: "inputComponent",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    inputSelect: false,
    width: "200px",
  },
];

const datosmultiHeader = {
  checkbox: [
    { label: "Sin Diagnóstico", value: "1", name: "a" },
    { label: "Sin Estado", value: "2", name: "b" },
    { label: "Sin Fecha Análisis", value: "3", name: "c" },
    { label: "Sin Imágenes", value: "4", name: "d" },
    { label: "Sin Resultados", value: "5", name: "e" },
    { label: "Sin Sugerencias", value: "6", name: "f" },
  ],
  buttons: [{ label: "Muestras" }, { label: "Resumen" }],
};

const Muestras = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [typeModal, setTypeModal] = useState("");
  const [key, setKey] = useState(1);

  const handleClickButonTable = (value, valueRow) => {
    console.log(value);
    const val = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (val !== "eliminar" && val !== "diagnostico") {
      setModalStatus(true);
      setTypeModal("muestras");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
    if (val === "diagnostico") {
      setModalStatus(true);
      setTypeModal("diagMuestras");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
  };

  const handleClickButtonHeader = (e) => {
    let modalType;
    if (e === "Nueva muestra") {
      modalType = "muestras"
    }
    setTypeModal(modalType);
    setDatosModal("");
    setModalStatus(true);
  };
  


  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        type={typeModal}
      />

      <HeaderTable
        styledHead="muestras"
        muestras
        title="Muestras"
        datosButtons={buttonsHeadTable}
        valueBuscador={(value) => console.log(value)}
        onClickButton={(e) => {
          handleClickButtonHeader(e);
        }}
        multiInputs
        selectInput={(value) => console.log(value)}
        buttonSet={() => {
          console.log("");
        }}
        clean
        onChangeCheckbox={(e) => {
          console.log(e);
        }}
        multiHeader
        datosMultiHeader={datosmultiHeader}
        valueBuscadorDos={(value) => console.log(value)}
        datosInputsHeaderTable={datosInputsHeaderTable}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={rows}
        cRows={20}
        totalRows={20}
        pagination={true}
        // onClickSwitch={(value) => {
        //   handleOrder(value);
        // }}
        // page={(e) => setPaginaActual(e + 1)}
        // pages={pageCount}
        buttons={buttonsMuestras}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      // valueBuscador={valueBuscador}
      />
    </Styled>
  );
};

export default Muestras;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
