import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input.jsx";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tables from "../../../../Tables/Tables.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Modal from "../../../Modal.jsx";
import dayjs from "dayjs";

import MenuItem from "@mui/material/MenuItem";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import BiotechIcon from "@mui/icons-material/Biotech";
import {
  getClienteOptions,
  getPlantaOptions,
  updateEquipos,
  createEquipos,
  getPlantabyCliente2,
} from "../../../../../api/Clientes.js";

import { StyledModalcomponentMuestras } from "../../../Styled.jsx";
import {
  avanzarOrdenTrabajo,
  createOrdenTrabajo,
  getEstadoOrdenesOptions,
  updateOrdenTrabajo,
} from "../../../../../api/Muestras.js";
import Preloader from "../../../../Preloader/Preloader.jsx";
import Toast from "../../../../Toast/Toast.jsx";

const columnsDefaultCfg = [
  {
    id: "button",
    label: (
      <CustomButton
        onClick={() => {
          alert("refrescar");
        }}
        startIcon={<RefreshIcon />}
        label={"Refrescar"}
        variant="contained"
        size="small"
        className={`buttonColorDisabled`}
      />
    ),
  },
  { id: "cantidadmuestras", label: "Cantidad Muestras" },
  { id: "resultados", label: "Resultados (Cargados/Totales)" },
];
const columnsDefaultCfgDos = [
  { id: "fecha", label: "Fecha " },
  { id: "usuario", label: "usuario" },
  { id: "accion", label: "Acción" },
  { id: "estado", label: "estado" },
];

const datosRows = [
  {
    button: "Sin diagnóstico",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin sugerencias",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin estado final",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin fecha de análisis",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin  imágenes",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin resultados",
    cantidadmuestras: "0",
    resultados: "0",
  },
  {
    button: "Listas para enviar",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Enviadas",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Total de muestras",
    cantidadmuestras: "0",
    resultados: "",
  },
];
const datosRowsDos = [
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
];

const OrdenesDeTrabajoModal = (props) => {
  const [statusModal, setModalStatus] = useState(false);
  const [labelModalNoti, setLabelModalNoti] = useState("");
  const propsOrdenesdeTrabajo = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const controlPanel = datosUnidades?.button === "controlpanel" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, controlPanel, editar, datos };
  };
  const { ver, controlPanel, editar, datos } = propsOrdenesdeTrabajo(
    props.datos
  );

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptioncli, setSelectedOptioncli] = useState(null);
  const [selectedOptionpla, setSelectedOptionpla] = useState(null);
  const [selectedOptionest, setSelectedOptionest] = useState(null);
  const [statusModal2, setModalStatus2] = useState(false);
  const [estados, setEstados] = useState([]);
  const [key, setKey] = useState(1);
  const [bloquearInput, setBloquearInput] = useState(
    ver || controlPanel ? true : false
  );
  const [fechaseleccionada, setFechaseleccionada] = useState(
    datos.fechaProgr ? dayjs(datos.fechaProgr) : null
  );

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      cliente: datos.clienteId ?? "",
      planta: datos.plantaId ?? "",
      fecha: datos.fechaProgr ? dayjs(datos.fechaProgr) : null,
      codigo: datos.codigo ?? "",
      lote: datos.lote ?? "",
      denominacion: datos.denominacion ?? "",
      plantaId: datos.plantaId ?? "",
      clienteId: datos.clienteId ?? "",
      estadoCompleto: datos.estadoComp ?? "",
    }
  );

  useEffect(() => {
    // const campoValido = (campo) => form[campo] !== "";
    const campoValido = (campo) => form[campo] !== null && form[campo] !== "";
    const camposaValidar = [
      "cliente",
      "planta",
      "codigo",
      "lote",
      "denominacion",
      "fecha",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  useEffect(() => {
    const fetchclientes = async () => {
      const res = await getClienteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.cliente
        );
        if (matchOption) {
          setSelectedOptioncli(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptioncli(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptioncli(unidades[0]);
      }
    };
    fetchclientes();

    const fetchPlantas = async () => {
      const res = await getPlantaOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_pla,
      }));
      setPlantas(unidades);
      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.planta
        );
        if (matchOption) {
          setSelectedOptionpla(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptionpla(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionpla(unidades[0]);
      }
    };

    if (editar || controlPanel) {
      fetchPlantas();
    }

    //getEstadoOrdenesOptions
    const fetchEstados = async () => {
      const res = await getEstadoOrdenesOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_ef_ot,
        color: item.color_ef_ot,
      }));
      setEstados(unidades);
      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.planta
        );
        if (matchOption) {
          setSelectedOptionest(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptionest(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionest(unidades[0]);
      }
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    setSelectedOptionpla(null);
    setForm({ type: "SET_FIELD", field: "planta", value: "" });
    if (form.cliente !== "" && !ver && selectedOptioncli !== null) {
      getPlantabyCliente2(selectedOptioncli.value).then((res) => {
        setPlantas(
          res.data.map((res) => {
            return { label: res.desc_pla, value: res.id };
          })
        );
        if (res.data.length === 0) {
          setForm({ type: "SET_FIELD", field: "planta", value: "" });
        }

        //setEquipos([])
      });
    }
  }, [selectedOptioncli]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      codigo_otra: form.codigo,
      nro_lote_otra: form.lote,
      desc_otra: form.denominacion,
      fecha_programada_otra: form.fecha,
      fecha_programada_otra_str: form.fecha.toLocaleString(),
      planta_id: form.planta,
      cliente_id: form.cliente,
      created_by: "usuario_creador",
      created_date: new Date(),
      eliminado: false,
      tenantId: 5,
      status: "SI",
    };

    if (editar || controlPanel) {
      console.log(JSON.stringify(formattedData));
      formattedData.id = form.id;
      updateOrdenTrabajo(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Orden de trabajo modificada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo modificar la Orden de trabajo.",
            status: "error",
          });
        });
    } else {
      createOrdenTrabajo(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Orden de trabajo creada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear la Orden de trabajo.",
            status: "error",
          });
        });
    }
  };

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      const label = e.label;

      if (name === "cliente") {
        // El cliente no se guarda, solo sirve para obtener las plantas.
        setSelectedOptionpla([]);
        setSelectedOptioncli(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "planta") {
        //REVISAR ACA
        setSelectedOptionpla(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "estado") {
        //REVISAR ACA
        setSelectedOptionest(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      }
    }
  };

  const handleChangeCalendar = (e, nameCalendar) => {
    if (e === null) {
      setForm({ type: "SET_FIELD", field: nameCalendar, value: null });
      return;
    }
    setFechaseleccionada(e);
    const name = nameCalendar;
    const value = e.$d;
    setForm({ type: "SET_FIELD", field: name, value: value });
  };

  const titulo = () => {
    const titulo = controlPanel
      ? "Control Panel Ordenes de Trabajo"
      : editar
      ? "Editar Ordenes de Trabajo"
      : "Crear o editar Ordenes de Trabajo";
    return titulo;
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={() => props.onClick({ msj: "", status: "cancel" })}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled btn`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={(e) => {
            handleSubmit(e);
          }}
          disabled={bloquearInput || !formCompleto}
          // disabled={!editar ? !formCompleto : false}
        />
      </>
    );
  };

  const AvanzarORden = async () => {
    avanzarOrdenTrabajo(form.id).then((res) => {
      console.log(JSON.stringify(res));
      console.log("datos" + res.data.estado);

      setForm({
        type: "SET_FIELD",
        field: "estadoCompleto",
        value: res.data.estado,
      });
      setKey(key + 1);

      ///aca ttengo que poner el nuesvo estadocompleto en el formulario y prubar..
    });
  };
  const handleModalNotificar = () => {
    return (
      <Modal
        datos={form}
        isOpen={statusModal}
        type={"notiordenesdetrabajo"}
        onClose={() => {
          setModalStatus(false);
        }}
        onClickSuccess={() => {
          AvanzarORden();
          setModalStatus(false);
        }}
        modalNav={true}
        titulo={labelModalNoti}
      />
    );
  };

  return (
    <StyledModalcomponentMuestras
      sx={
        controlPanel
          ? {
              width: "70%",
              "@media (max-width: 768px)": {
                width: "90%",
                overflowY: "scroll",
                height: "70%",
              },
              "@media (max-width: 1500px)": {
                width: "90%",
                height: "80%",
                overflowY: "scroll",
              },
            }
          : {
              width: "60%",
              "@media (max-width: 768px)": {
                width: "90%",
                overflowY: "scroll",
                height: "70%",
              },
              "@media (max-width: 1400px)": {
                width: "90%",
                height: "80%",
                overflowY: "scroll",
              },
            }
      }
    >
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la orden ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>
      {handleModalNotificar()}

      {!controlPanel ? (
        <div className="ordenesDeTrabajo">
          <div className="divLabel">
            <p className="titulo"> {titulo()} </p>
          </div>
          <div className="divInputsItem">
            <form className="formulario" onSubmit={handleSubmit}>
              <div className="divInputsUno">
                <div style={{ width: "33%" }}>
                  <Input
                    reactSelect
                    options={clientes}
                    value={selectedOptioncli}
                    onChange={(e) => {
                      handleChange(e, "cliente");
                    }}
                    isDisabled={ver && true}
                    placeholder={
                      ver ? "Cliente no disponible" : "Seleccione el cliente"
                    }
                  />
                </div>
                <div style={{ width: "33%" }}>
                  <Input
                    reactSelect
                    options={plantas}
                    value={selectedOptionpla}
                    onChange={(e) => {
                      handleChange(e, "planta");
                    }}
                    isDisabled={
                      datos.length === 0 && form.cliente === "" ? true : ver
                    }
                    placeholder={
                      form.cliente === ""
                        ? "Seleccione un cliente primero"
                        : "Selecciona la planta"
                    }
                  />
                </div>
                <div
                  style={{
                    width: "33%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        className="inputDate"
                        label="Fecha programada"
                        disabled={ver}
                        // defaultValue={form}
                        value={fechaseleccionada}
                        slotProps={
                          <Input
                            className="input inputDate"
                            variant="outlined"
                            size={"medium"}
                          />
                        }
                        onChange={(e) => {
                          handleChangeCalendar(e, "fecha");
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="divInputsDos">
                <Input
                  value={form.codigo}
                  label="Código"
                  classnamediv="divInputCodigo divInput"
                  className="input inputCodigo"
                  variant="outlined"
                  size={"medium"}
                  name="codigo"
                  onChange={handleChange}
                  disabled={bloquearInput}
                  // disabled={ver && true}
                />
                <Input
                  value={form.lote}
                  label="Lote"
                  classnamediv="divInputLote divInput"
                  className="input inputLote"
                  variant="outlined"
                  size={"medium"}
                  name="lote"
                  type="number"
                  disabled={ver}
                  onChange={handleChange}
                />
                <Input
                  value={form.denominacion}
                  label="Denominación"
                  classnamediv="divInputDescripcion divInput"
                  className="input inputDescripcion"
                  variant="outlined"
                  size={"medium"}
                  name="denominacion"
                  disabled={ver}
                  onChange={handleChange}
                />
              </div>
              <div className="divButtonsItem">{renderButtons()}</div>
            </form>
          </div>
        </div>
      ) : (
        <div key={key} className="ordenesDeTrabajoPanelControl">
          <div className="divLabel">
            <p className="titulo"> Control Panel Ordenes de Trabajo </p>
          </div>
          <form className="formularioPanelControl">
            <div className="divColumnaUno">
              <div className="divHeader">
                <CustomButton
                  onClick={() => {
                    setBloquearInput(!bloquearInput);
                  }}
                  startIcon={<CreateOutlinedIcon />}
                  label={"Editar OT"}
                  variant="contained"
                  className={`buttonColorTable`}
                />
              </div>
              <div className="divInputsPanelControl">
                <div className="divUnoPanelControl">
                  <div style={{ width: "50%" }}>
                    <Input
                      value={form.codigo}
                      label="Código"
                      classnamediv=" divInput"
                      className="input "
                      variant="outlined"
                      size={"medium"}
                      name="codigo"
                      onChange={handleChange}
                      disabled={bloquearInput}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Input
                      value={form.estadoCompleto?.desc_ef_ot}
                      label="Estado"
                      classnamediv=" divInput"
                      className="input "
                      variant="outlined"
                      size={"medium"}
                      name="codigo"
                      disabled={true}
                      //onChange={handleChange}//Este valor no se puede cambiar manualmente
                    />
                    {/* <Input
                      reactSelect
                      value={selectedOptionest}
                      options={estados}
                      onChange={(e) => {
                        handleChange(e, "estado");
                      }}
                      isDisabled={ver && true}
                      placeholder={
                        ver ? "estado no disponible" : "Seleccione el estado"
                      }
                    /> */}
                  </div>
                </div>
                <div
                  className="divDosPanelControl"
                  style={{ flexDirection: "row" }}
                >
                  <div style={{ width: "46%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          className="inputDate2"
                          label="Fecha programada"
                          placeholder="Fecha programada"
                          value={form.fecha}
                          onChange={(e) => {
                            handleChangeCalendar(e, "fecha");
                          }}
                          disabled={bloquearInput}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div style={{ width: "20%" }}>
                    <Input
                      value={form.lote}
                      label="Lote"
                      classnamediv="divInputLote divInput"
                      className="input inputLote"
                      variant="outlined"
                      size={"medium"}
                      name="lote"
                      onChange={handleChange}
                      disabled={bloquearInput}
                    />
                  </div>
                  <div style={{ width: "33%" }}>
                    <Input
                      value={form.denominacion}
                      label="Descripción"
                      classnamediv="divInputDescripcion divInput"
                      className="input inputDescripcion"
                      variant="outlined"
                      size={"medium"}
                      name="denominacion"
                      onChange={handleChange}
                      disabled={bloquearInput}
                    />
                  </div>
                </div>
                <div
                  className="divDosPanelControl"
                  style={{ flexDirection: "row" }}
                >
                  <div style={{ width: "50%" }}>
                    <Input
                      reactSelect
                      options={clientes}
                      value={selectedOptioncli}
                      onChange={(e) => {
                        handleChange(e, "cliente");
                      }}
                      isDisabled={bloquearInput}
                      placeholder={
                        selectedOptioncli === ""
                          ? "Cliente no disponible"
                          : "Cliente"
                      }
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Input
                      reactSelect
                      options={plantas}
                      value={selectedOptionpla}
                      onChange={(e) => {
                        handleChange(e, "planta");
                      }}
                      // isDisabled={
                      //   bloquearInput && form.cliente === "" ? true : false
                      // }
                      isDisabled={bloquearInput}
                      placeholder={
                        selectedOptionpla === ""
                          ? "Seleccione un cliente primero."
                          : "Planta"
                      }
                    />
                  </div>
                </div>
                <div className="divTresPanelControl">
                  {form.estadoCompleto &&
                  form.estadoCompleto.final_sn_ef_ot === "SI" ? (
                    ""
                  ) : (
                    <CustomButton
                      startIcon={<ForwardToInboxIcon />}
                      label={form.estadoCompleto?.accion_ef_ot}
                      variant="contained"
                      size={"small"}
                      className={`buttonColorDefault`}
                      onClick={() => {
                        setModalStatus(true);
                        //avanzarOrdenTrabajo(form.id);
                      }}
                    />
                  )}
                </div>
                <div className="divCuatroPanelControl">
                  <p className="title">Cambios de Estado</p>
                  <Tables
                    columns={[]}
                    rows={datosRowsDos}
                    typeTable={"prop"}
                    pagination={true}
                    tableColor={false}
                  />
                </div>
              </div>
            </div>
            <div className="divColumnaDos">
              <div className="divHeaderColumnaDos">
                <div className="buttonUno">
                  <CustomButton
                    onClick={() => {
                      alert("crear nueva muestra");
                    }}
                    startIcon={<AddIcon />}
                    label={"Crear nueva muestra"}
                    variant="contained"
                    size="small"
                    className={`buttonHeaderTable`}
                  />
                </div>
                <div className="buttonsDos">
                  <CustomButton
                    onClick={() => {
                      alert("diag.masivo");
                    }}
                    startIcon={<BiotechIcon />}
                    label={"Diag. Masivo"}
                    variant="contained"
                    size="small"
                    className={`buttonColorTable`}
                  />
                  <CustomButton
                    onClick={() => {
                      alert("excel");
                    }}
                    startIcon={<ArticleIcon />}
                    label={"Excel resultados"}
                    variant="contained"
                    size="small"
                    className={`buttonColorTable`}
                  />
                </div>
              </div>
              <Tables
                columns={columnsDefaultCfg}
                rows={datosRows}
                typeTable={"prop"}
                pagination={false}
                tableColor={true}
              />
            </div>
          </form>
          <div className="divButtonsItem">{renderButtons()}</div>
        </div>
      )}
    </StyledModalcomponentMuestras>
  );
};

export default OrdenesDeTrabajoModal;
