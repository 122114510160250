import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import {
  deleteEstadosFinales,
  getEstadosFinalesPaginated,
  getEstadosFinalesSearch,
} from "../../../../api/Configuracion";
import Modal2 from "../../../../components/Modal/Modal";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "abreviatura", label: "Abreviatura" },
  { id: "orden", label: "Orden" },
  { id: "color", label: "Color" },
  { id: "imagen", label: "Imagen" },
  { id: "buttons" },
];
const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    abreviatura: "Abreviatura",
    orden: "Orden",
    color: "Color",
    imagen: "Imagen",
    id: "5",
  },
];

const EstadosFinales = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [estadosFinales, setEstadosFinales] = useState([]);
  const [estadosFinalesId, setEstadosFinalesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchEstadosFinalesPaginated(paginaActual, orderBy, searchTerm, "");
  }, [paginaActual]);

  const fetchEstadosFinalesPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res =
      search === ""
        ? await getEstadosFinalesPaginated(pagina, order, search)
        : await getEstadosFinalesSearch(search);

    // console.log("res:", res); //////////--------ACAAAAAAA--------//////////
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new" && estadosFinales.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Estado final eliminado con exito");
      setSuccess(true);
    }

    setEstadosFinales(
      res.data.items.map((res) => {
        let imagen;
        if (res.imagen_efm === null || res.imagen_efm === "") {
          imagen = "";
        } else {
          imagen = `${res.imagen_efm_content_type},${res.imagen_efm}`;
        }
        return {
          id: res.id,
          codigo: res.codigo_efm,
          descripcion: res.desc_efm,
          abreviatura: res.abrev_efm,
          orden: res.orden_efm,
          color: res.color_efm,
          habilitado: res.status,
          imagen: imagen,
          codigo_template_email: res.codigo_template_email_efm,
        };
      })
    );
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setEstadosFinalesId(valueRow.id);
      setModalStatus2(true);
      // handleDelete(valueRow.id)
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDelete = (id) => {
    deleteEstadosFinales(id)
      .then(() => {
        setEstadosFinalesId("");
        let paginaActualTemp = paginaActual;
        if (estadosFinales.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchEstadosFinalesPaginated(
          paginaActual,
          orderBy,
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        // setKey(key + 1);
        setMsjToast("Error al eliminar el estado final.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };
  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchEstadosFinalesPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchEstadosFinalesPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              "new"
            );
          } else if (status === "error") {
            // setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          } else {
            setKey(key + 1);
            fetchEstadosFinalesPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              "ver"
            );
          }
          setModalStatus(false);
        }}
        type={"estadosFinales"}
        // iconX={valueTicket === "Ver"}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Estados Finales"
        valueBuscador={(value) => handleBuscador(value)}
        labelButton={"Crear nuevo estado final"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={estadosFinales}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(estadosFinalesId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default EstadosFinales;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
